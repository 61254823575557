import styled from "styled-components";
import {motion} from "framer-motion";
import BtnDefault from "../../../common/btn-default";

const BtnBx = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
`

const BtnContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`


interface Props{
    answerList?: any[];
    sendMessage: () => void;
    onClose: () => void;
}

const QuizNextBtn = ({ answerList, sendMessage, onClose }: Props ) => {
    return(
        <BtnBx>
            <motion.div
                initial={{opacity: 0, y: 10}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0, y: 32}}
                transition={{duration: 0.5, ease: 'easeOut'}}
            >
                {
                    answerList && answerList.length > 0 && <BtnContainer>
                        <BtnDefault onClick={sendMessage}>다음 문제</BtnDefault>
                        <BtnDefault type="red" onClick={onClose}>종료</BtnDefault>
                    </BtnContainer>
                }
            </motion.div>
        </BtnBx>
    )
}

export default QuizNextBtn;