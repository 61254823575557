import * as S from "./styles";
import BtnDefault from "../common/btn-default";

import {SetSignup} from "../../api/user";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {isValidEmail} from "../../libs/check";
import {useAuthContext} from "../auth-provider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faLock, faUserPlus} from "@fortawesome/free-solid-svg-icons";

const Signup = () => {
    const navigate = useNavigate();
    const {user} = useAuthContext();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [username, setUsername] = useState<string>('');

    const onClick = async () => {
        try {
            if (!isValidEmail(email)) {
                alert("올바른 이메일을 입력해주세요.");
                return;
            }

            if (!password) {
                alert("비밀번호를 입력해주세요.");
                return;
            }

            if (!username) {
                alert("아이디를 입력해주세요.");
                return;
            }

            await SetSignup({
                email, password, username
            });

            alert("회원 가입하였습니다.");
            navigate("/login");
        } catch (err: any) {
            alert(err.response.data.message);
        }
    }

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user]);

    return (
        <S.Wrap>
            <S.Inner>
                <S.TitleBx>
                    <FontAwesomeIcon icon={faUserPlus} size="lg" style={{marginRight: '8px'}} />
                    회원가입
                </S.TitleBx>
                <S.InputContainer>
                    <S.InputBx>
                        <label htmlFor="">
                            <FontAwesomeIcon icon={faEnvelope} style={{marginRight: '8px'}} />
                            이메일
                        </label>
                        <input
                            type="text"
                            placeholder={'example@email.com'}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </S.InputBx>
                    <S.InputBx>
                        <label htmlFor="">
                            <FontAwesomeIcon icon={faLock} style={{marginRight: '8px'}} />
                            비밀번호
                        </label>
                        <input
                            type="password"
                            placeholder='******'
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </S.InputBx>
                    <S.InputBx>
                        <label htmlFor="">
                            <FontAwesomeIcon icon={faUserPlus} style={{marginRight: '8px'}} />
                            아이디
                        </label>
                        <input
                            type="text"
                            placeholder={'홍길동'}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </S.InputBx>
                </S.InputContainer>

                <BtnDefault onClick={onClick} type="red">
                    <FontAwesomeIcon icon={faUserPlus} style={{marginRight: '8px'}} />
                    가입하기
                </BtnDefault>
            </S.Inner>
        </S.Wrap>
    )
}

export default Signup;