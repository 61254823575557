import * as S from "./styles"
import BtnDefault from "../../common/btn-default";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap, faTrophy } from "@fortawesome/free-solid-svg-icons";

const QuizMain = () => {
    return (
        <S.Wrap style={{ padding: '50px 20px' }}>
            <S.Inner style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'center' }}>
                <S.TitleBx>
                    <dl>
                        <dt style={{ color: '#333', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                            <FontAwesomeIcon icon={faGraduationCap} style={{ fontSize: '28px', color: '#2196f3' }} />
                            English Tail Quiz
                        </dt>
                        <dd style={{ color: '#666', lineHeight: '1.4' }}>
                            빈칸의 영어 단어를 맞쳐보세요.<br />
                            퀴즈를 통해 영어 실력을 향상시켜보세요!
                        </dd>
                    </dl>
                </S.TitleBx>

                <S.BtnBx style={{ marginTop: '30px' }}>
                    <BtnDefault link={'/quiz/play'}>
                        <FontAwesomeIcon icon={faGraduationCap} style={{ marginRight: '8px' }} />
                        퀴즈 시작
                    </BtnDefault>

                    <BtnDefault link={'/ranking'} type={'red'}>
                        <FontAwesomeIcon icon={faTrophy} style={{ marginRight: '8px' }} />
                        랭킹 확인
                    </BtnDefault>
                </S.BtnBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default QuizMain;