import * as S from "./styles";
import {WordGroup} from "../../../api/mock/wordgroup";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGraduationCap, faBook} from "@fortawesome/free-solid-svg-icons";

const Word = () => {
    return (
        <S.Wrap>
            <S.Inner>
                <S.HeaderBx>
                    <dl>
                        <dt>
                            <FontAwesomeIcon icon={faBook} style={{
                                marginRight: '12px',
                                fontSize: '20px',
                                color: '#2C3E50'
                            }}/>
                            영어 단어
                        </dt>
                        <dd>
                            영어 단어를 공부해보세요.
                            <br/>
                            (업데이트 중입니다.)
                        </dd>
                    </dl>
                </S.HeaderBx>
                <S.WordList>
                    {
                        WordGroup.map((group, groupId) => {
                            let levelColor = '#2C3E50';
                            if (group.title.includes('기초')) {
                                levelColor = '#34495E';
                            } else if (group.title.includes('중급')) {
                                levelColor = '#2C3E50';
                            } else if (group.title.includes('고급')) {
                                levelColor = '#1A2533';
                            }

                            return (
                                <div key={`group-${groupId}`} style={{
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '4px',
                                    padding: '16px',
                                    marginBottom: '16px',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.08)'
                                }}>
                                    <h3 style={{
                                        fontSize: '18px',
                                        fontWeight: '600',
                                        color: levelColor,
                                        marginBottom: '12px',
                                        borderBottom: `1px solid ${levelColor}40`,
                                        paddingBottom: '8px'
                                    }}>
                                        <FontAwesomeIcon icon={faGraduationCap} style={{
                                            marginRight: '10px',
                                            fontSize: '16px'
                                        }}/>
                                        {group.title}
                                    </h3>
                                    <ul style={{
                                        listStyle: 'none',
                                        padding: 0,
                                        margin: 0,
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                        gap: '12px'
                                    }}>
                                        {
                                            group.list.map((item, itemId) => (
                                                <li key={`group-list-${groupId}-${itemId}`}
                                                    style={{
                                                        transition: 'transform 0.2s ease'
                                                    }}
                                                    onMouseOver={(e) => {
                                                        e.currentTarget.style.transform = 'translateY(-2px)'
                                                    }}
                                                    onMouseOut={(e) => {
                                                        e.currentTarget.style.transform = 'translateY(0)'
                                                    }}>
                                                    <Link to={`/memorize/word-list/${item.groupId}`}
                                                          style={{
                                                              display: 'flex',
                                                              justifyContent: 'space-between',
                                                              alignItems: 'center',
                                                              padding: '12px 16px',
                                                              backgroundColor: '#F8FAFC',
                                                              borderRadius: '3px',
                                                              color: levelColor,
                                                              textDecoration: 'none',
                                                              fontWeight: '500',
                                                              fontSize: '14px',
                                                              border: `1px solid ${levelColor}15`,
                                                              transition: 'all 0.2s ease'
                                                          }}
                                                          onMouseOver={(e) => {
                                                              e.currentTarget.style.backgroundColor = levelColor
                                                              e.currentTarget.style.color = '#FFFFFF'
                                                          }}
                                                          onMouseOut={(e) => {
                                                              e.currentTarget.style.backgroundColor = '#F8FAFC'
                                                              e.currentTarget.style.color = levelColor
                                                          }}>
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <FontAwesomeIcon icon={faBook} style={{
                                                                marginRight: '10px',
                                                                fontSize: '13px'
                                                            }}/>
                                                            {item.title}
                                                        </div>
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            )
                        })
                    }
                </S.WordList>
            </S.Inner>
        </S.Wrap>
    )
}

export default Word;