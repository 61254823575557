import {WordListDetailData} from "../api/mock/word";

const randomWord: string[] = [];
const randomWords: any = {
    "lv1": [],
    "lv2": [],
    "lv3": [],
};

WordListDetailData.forEach((item) => {
    const level = 'lv' + item.detailId.substr(0, 1);
    item.list.forEach((item) => {
        randomWords[level].push(item.word);
        randomWord.push(item.word);
    })
});

export const getRandomWord = () => {
    const random = Math.floor(randomWord.length * Math.random());
    return randomWord[random];
}

export const getRandomWordLevel = (lv: string) => {
    const random2 = Math.floor(randomWords[lv].length * Math.random());
    return randomWords[lv][random2];
}

export const getRandomAnswer = (word: string) => {
    const randomNums: number[] = [0, 1, 2, 3];
    const randomAnswerList: any[] = [];

    let f = Math.floor(Math.random() * (randomNums.length - 1));
    let r = randomNums[f];

    randomAnswerList[r] = {
        word: word,
        isAnswer: true,
    };

    randomNums.splice(f, 1);

    let i = 0;
    let len = randomNums.length;

    for (i; i < len; i++) {
        f = Math.floor(Math.random() * (randomNums.length - 1));
        r = randomNums[f];

        randomAnswerList[r] = {word: getRandomWord(), isAnswer: false};
        randomNums.splice(f, 1);
    }

    return randomAnswerList;
}
