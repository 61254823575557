import React from 'react';
import {Route, Routes} from "react-router-dom";

import HomePage from "./pages/HomePage";
import SentencePage from "./pages/SentencePage";
import WordPage from "./pages/memorize/WordPage"
import WordListPage from "./pages/memorize/WordListPage";
import WordDetailPage from "./pages/memorize/WordDetailPage";
import InfoPage from "./pages/InfoPage";

import SignupPage from "./pages/SignupPage";
import LoginPage from "./pages/LoginPage";

import {AnimatePresence} from "framer-motion";
import {useLocation} from "react-router-dom";

import BottomMenu from "./component/bottom-menu";

import AuthProvider from "./component/auth-provider";

import QuizPage from "./pages/quiz/QuizPage";
import QuizPlayPage from "./pages/quiz/QuizPlayPage";
import RankingPage from "./pages/quiz/RankingPage";

function App() {
    const location = useLocation();

    return (
        <div className="App">
            <AuthProvider>
                <AnimatePresence mode={"wait"}>
                    <Routes location={location} key={location.key}>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/sentence" element={<SentencePage/>}/>
                        <Route path="/memorize">
                            <Route path="word" element={<WordPage/>}/>
                            <Route path="word-list/:groupId" element={<WordListPage/>}/>
                            <Route path="word-detail/:detailId" element={<WordDetailPage/>}/>
                        </Route>
                        <Route path="/quiz">
                            <Route path="info" element={<QuizPage/>}></Route>
                            <Route path="play" element={<QuizPlayPage/>}></Route>
                        </Route>

                        <Route path="/ranking" element={<RankingPage/>}></Route>

                        <Route path="/info" element={<InfoPage/>}></Route>
                        <Route path="/signup" element={<SignupPage/>}></Route>
                        <Route path="/login" element={<LoginPage/>}></Route>
                    </Routes>
                </AnimatePresence>
            </AuthProvider>
            <BottomMenu/>
        </div>
    );
}

export default App;
