import styled from "styled-components";


export const Wrap = styled.div`
    
`

export const Inner = styled.div`
    
`

export const TitleBx = styled.div`
    dl{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 50px;
        dt{
            font-size: 24px;
            font-weight: 700;
        }
        dd{
            font-size: 16px;
            font-weight: 600;
        }
    }
`

export const BtnBx = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
`