import {motion} from "framer-motion";
import {useState} from "react";
import * as S from "./styles";
import {getChatGPSpeech} from "../../../api/chatgptapi";

interface Props {
    soundText: string;
}

const SoundButton = ({soundText}: Props) => {
    const [isPlay, setPlay] = useState<boolean>(false);
    const [audioUrl, setAudioUrl] = useState<string>("");

    const onClickPlay = async () => {
        if (isPlay) return;

        const response = await getChatGPSpeech(soundText);

        const data = await response.json();
        const audioContent = data.audioContent;

        const audioBlob = new Blob([Uint8Array.from(atob(audioContent), c => c.charCodeAt(0))], {type: 'audio/mp3'});
        const audioUrl = URL.createObjectURL(audioBlob);

        const audio = new Audio(audioUrl);
        const endedListener = () => {
            setPlay(false);
        }

        const playListener = () => {
            setPlay(true);
        }

        audio.addEventListener('ended', endedListener);
        audio.addEventListener("play", playListener);
        audio.play();
    }


    return (
        <S.Wrap>
            <S.Inner
                initial={{opacity: 0, y: 10}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0, y: 32}}
                transition={{duration: 0.5, ease: 'easeOut'}}
                onClick={onClickPlay}
                $isPlay={isPlay}
            >
                <motion.button>
                    {
                        isPlay ? (
                            <S.EffectBx>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </S.EffectBx>
                        ) : (
                            <img src="/icon/ic_arrow_down.svg" alt=""/>
                        )
                    }
                </motion.button>

                {audioUrl && (
                    <div>
                        <h3>Generated Audio:</h3>
                        <audio controls src={audioUrl}/>
                    </div>
                )}

                <S.PlayingText $isPlay={isPlay}>
                    {
                        isPlay ? '재생중...' : '발음 듣기'
                    }
                </S.PlayingText>
            </S.Inner>
        </S.Wrap>
    )
}

export default SoundButton;