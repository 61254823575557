import {useEffect, useState} from "react";
import {getMyQuizRanking, getQuizRanking} from "../../../api/quiz";
import * as S from "./styles";
import {getComma} from "../../../libs/comma";
import {useAuthContext} from "../../auth-provider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrophy, faUser, faMedal, faStar, faList, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

const Ranking = () => {
    const {user} = useAuthContext();
    const [rankingList, setRankiList] = useState<{
        name: string; score: number
    }[]>();

    const [myRanking, setMyRanking] = useState<any>();

    const getRankingList = async () => {
        const data = await getQuizRanking();
        setRankiList(data);

        if (user) {
            const myData = await getMyQuizRanking(+user.id);
            setMyRanking(myData);
        }
    }

    useEffect(() => {
        getRankingList();
    }, [user]);

    return (
        <S.Wrap>
            <S.Inner>
                <S.TitleBx>
                    <dl style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '12px',
                        padding: '20px 0'
                    }}>
                        <dt style={{
                            fontSize: '28px',
                            fontWeight: '700',
                            color: '#333',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px'
                        }}>
                            <FontAwesomeIcon icon={faTrophy} style={{
                                color: '#FFD700',
                                fontSize: '32px',
                                filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                            }}/>
                            랭킹
                        </dt>
                        <dd style={{
                            fontSize: '16px',
                            color: '#666',
                            fontWeight: '500'
                        }}>랭킹을 확인해보세요.</dd>
                    </dl>
                </S.TitleBx>

                {
                    myRanking && <S.MyRanking>
                        <h3 style={{
                            fontSize: '20px',
                            fontWeight: '700',
                            marginBottom: '15px',
                            textAlign: 'center',
                            color: '#333',
                            padding: '10px 0',
                        }}>
                            <FontAwesomeIcon icon={faUser} style={{marginRight: '12px', color: '#4A90E2'}}/>
                            내 랭킹
                        </h3>
                        <dl style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '15px 20px',
                            backgroundColor: '#f8f9fa',
                            borderRadius: '10px',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                            boxSizing: 'border-box'
                        }}>
                            <dt style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '15px'
                            }}>
                                <span style={{
                                    backgroundColor: myRanking.ranking <= 3 ? '#FFD700' : '#f0f0f0',
                                    padding: '8px 12px',
                                    borderRadius: '20px',
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    color: myRanking.ranking <= 3 ? '#000' : '#666'
                                }}>
                                    <FontAwesomeIcon icon={faMedal} style={{marginRight: '6px'}}/>
                                    {myRanking.ranking}
                                </span>
                                <span style={{
                                    fontSize: '18px',
                                    fontWeight: '600',
                                    color: '#333'
                                }}>
                                    {myRanking.name}
                                </span>
                            </dt>

                            <dd style={{
                                fontSize: '20px',
                                fontWeight: '700',
                                color: '#4A90E2'
                            }}>
                                <FontAwesomeIcon icon={faStar} style={{marginRight: '8px', color: '#FFD700'}}/>
                                {getComma(myRanking.score)}
                            </dd>
                        </dl>
                    </S.MyRanking>
                }

                <S.RankingListBx>
                    <h3>
                        <FontAwesomeIcon icon={faList} style={{marginRight: '8px', color: '#4A90E2'}}/>
                        랭킹 리스트
                    </h3>

                    <ul>
                        {
                            rankingList && rankingList.length > 0 ? (
                                rankingList?.map((item, idx) => (
                                    <li key={`ranking-${idx}`}>
                                        <dl>
                                            <dt>
                                                <span style={{
                                                    backgroundColor: idx < 3 ? ['#FFD700', '#C0C0C0', '#CD7F32'][idx] : '#f0f0f0',
                                                    padding: '4px 8px',
                                                    borderRadius: '12px', 
                                                    fontSize: '12px',
                                                    fontWeight: '600',
                                                    color: idx < 3 ? '#000' : '#666'
                                                }}>
                                                    <FontAwesomeIcon 
                                                        icon={faMedal} 
                                                        style={{
                                                            marginRight: '3px',
                                                            color: idx < 3 ? '#000' : '#A9A9A9'
                                                        }}
                                                    />
                                                    {idx + 1}
                                                </span>
                                                {item.name}
                                            </dt>
                                            <dd>
                                                <FontAwesomeIcon icon={faStar} style={{marginRight: '4px', color: '#FFD700'}}/>
                                                <span>
                                                    {getComma(item.score)}
                                                </span>
                                            </dd>
                                        </dl>
                                    </li>
                                ))
                            ) : (
                                <div>
                                    <FontAwesomeIcon icon={faExclamationCircle} style={{marginRight: '8px', color: '#999'}}/>
                                    랭킹 정보가 없습니다.
                                </div>
                            )
                        }
                    </ul>
                </S.RankingListBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default Ranking;