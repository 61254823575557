import Layout from "../../component/layout";
import Login from "../../component/login";
const LoginPage = () => {
    return(
        <Layout>
            <Login />
        </Layout>
    )
}

export default LoginPage;