export const WordGroup = [
    {
        wordId: "1",
        title: '기초',
        list: [
            {
                title: '기초 영단어 1',
                groupId: "101",
            },
            {
                title: '기초 영단어 2',
                groupId: "102",
            },
        ]
    },
    {
        wordId: "2",
        title: '중급',
        list: [
            {
                title: '중급 영단어 1',
                groupId: "201",
            },
            {
                title: '중급 영단어 2',
                groupId: "202",
            }
        ]
    },
    {
        wordId: "3",
        title: '고급',
        list: [
            {
                title: '고급 영단어 1',
                groupId: "301",
            },
            {
                title: '고급 영단어 2',
                groupId: "302",
            }
        ]
    },
]