import {createContext, useContext, ReactNode, useState, useEffect} from "react";
import {GetUser} from "../../api/user";

interface AuthContextType {
    user: any;
    setUserData: (data: any) => void;
    loginOut: () => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);

interface Props{
    children: ReactNode
}

const AuthProvider = ({ children }: Props ) => {
    const [user, setUser] = useState<any>();

    const setUserData = (data: any) => {
        setUser(data)
    }

    const loginOut = () => {
        setUser(null);
    }

    const getUser = async () => {
        try {
            const data = await GetUser();
            setUser(data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getUser();
    }, []);

    return (
        <AuthContext.Provider value={{ user, setUserData, loginOut}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};


export default AuthProvider;