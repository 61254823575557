import Ranking from "../../../component/quiz/ranking";
import {motion} from "framer-motion";

const RankingPage = () => {
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: .5, ease: 'easeOut'}}
        >
            <Ranking/>
        </motion.div>
    )
}

export default RankingPage;