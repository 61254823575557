import axios from "axios";
import {api} from "./apiLink";

export const SetSignup = async (userData: {
    email: string;
    password: string;
    username: string;
}) => {
    const {data} = await axios.post(api + "/user/signup", {
        userData,
    });

    return data;
}

export const SetLogin = async (email: string, password: string) => {
    const {data} = await axios.get(api + "/user/login", {
        withCredentials: true,
        params: {
            email: email, password: password
        }
    });

    return data;
}

export const GetUser = async () => {
    const { data } = await axios.get( api + "/user/info", {
        withCredentials: true,
    });

    return data;
}