import * as S from "./styles";
import {ReactNode} from "react";
import {Link} from "react-router-dom";


interface Props {
    link?: string;
    onClick?: () => void;
    type?: string;
    children: ReactNode
}

const BtnDefault = ({link, onClick, type = "BLUE", children}: Props) => {
    return (
        <S.Bx $type={type}>
            {
                link ? (
                    <Link to={link}>
                        {children}
                    </Link>
                ) : (
                    <button onClick={onClick}>
                        {children}
                    </button>
                )
            }
        </S.Bx>
    )
}

export default BtnDefault;