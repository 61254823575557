import * as S from "./styles";
import {Link} from "react-router-dom";
import {motion} from "framer-motion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGraduationCap} from "@fortawesome/free-solid-svg-icons";

const Home = () => {
    return (
        <>
            <S.LinkBx
                initial={{y: 15}}
                animate={{y: 0}}
                exit={{y: -15}}
                transition={{
                    duration: 0.5,
                    ease: 'easeOut'
                }}
            >
                <dl>
                    <motion.dt
                        initial={{y: 20, opacity: 0}}
                        animate={{y: 0, opacity: 1}}
                        transition={{
                            duration: 0.4,
                            ease: 'easeOut',
                            delay: 0.3,
                        }}
                    >
                        <span>[영어 단어]</span>를 클릭하면<br/>
                        관련된 문장이 작성됩니다.
                    </motion.dt>
                    <motion.dd
                        initial={{y: 20, opacity: 0}}
                        animate={{y: 0, opacity: 1}}
                        transition={{
                            duration: 0.4,
                            delay: 0.5,
                            ease: 'easeOut'
                        }}
                    >
                        <Link to={"/sentence"}>
                            <FontAwesomeIcon icon={faGraduationCap} style={{marginRight: '8px'}}/>
                            START LEARNING
                        </Link>
                    </motion.dd>
                </dl>
            </S.LinkBx>
        </>
    )
}

export default Home;