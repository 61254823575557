import QuizMain from "../../../component/quiz/quiz-main";
import Layout from "../../../component/layout";

const QuizPage = () => {
    return (
        <Layout>
            <QuizMain/>
        </Layout>
    )
}

export default QuizPage;