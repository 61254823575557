import * as S from "./styles";
import {useEffect, useRef, useState} from "react";
import gsap, {Power0} from "gsap";

interface Props {
    onClickClose: () => void;
    score: number;
    changeLevel: (lv: string) => void;
}

const QuizHeader = ({onClickClose, score, changeLevel}: Props) => {
    const scoreRef = useRef<any>(null);
    const [oldScore, setOldScore] = useState<number>(0);

    useEffect(() => {
        if (scoreRef.current) {
            const scoreEl = scoreRef.current as any;
            const obj = {
                score: oldScore
            }

            gsap.to(obj, {
                duration: 0.5,
                score: score,
                ease: Power0.easeInOut,
                onUpdate: () => {
                    scoreEl.innerText = Math.round(obj.score);
                },
                onComplete: () => {
                    setOldScore(score);
                }
            });
        }
    }, [score, oldScore]);

    return (
        <S.HeaderContainer>
            <S.HeaderBx>
                <S.LevelBx>
                    <div>
                        <select onChange={(e) => changeLevel(e.target.value)}>
                            <option value="lv1">난이도 : 초급 (+10)</option>
                            <option value="lv2">난이도 : 중급 (+15)</option>
                            <option value="lv3">난이도 : 고급 (+20)</option>
                        </select>
                        <img src="/icon/ic_arrow_down.svg" alt=""/>
                    </div>
                </S.LevelBx>

                <S.CloseBtn onClick={onClickClose}>
                    <img src="/icon/ic_btn_close.svg" alt=""/>
                </S.CloseBtn>
            </S.HeaderBx>
            <S.ScoreBx>
                <dl>
                    <dt>현재 점수 :</dt>
                    <dd ref={scoreRef}>{score}</dd>
                </dl>
            </S.ScoreBx>
        </S.HeaderContainer>
    )
}

export default QuizHeader;