import styled from "styled-components";
import {motion} from "framer-motion";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 50px;
`

export const WordBx = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    dl {
        dt {
            text-align: center;
            font-size: 34px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        dd {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
        }
    }
`

export const LoadingBx = styled.div`
    position: absolute;
    width: 300px;
    left: 50%;
    top: 5px;
    transform: translateX(-50%);
`

export const SentenceBx = styled.div`
    position: relative;
    height: 400px;
`

export const BtnBx = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: 5px;
`