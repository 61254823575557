import styled from "styled-components";

export const Wrap = styled.div`
    dl {
        display: flex;
        flex-direction: column;

        dt {
            margin-bottom: 30px;

            ul {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                column-gap: 10px;
                row-gap: 5px;

                li {
                    position: relative;
                    cursor: pointer;
                    font-size: 26px;
                    font-weight: 600;
                    overflow: hidden;
                    height: 30px;

                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                    &:hover {
                        color: blue;
                    }

                    span {
                        display: block;
                        position: relative;

                        &:after {
                            opacity: 0;
                            content: '↗';
                            position: absolute;
                            right: 0;
                            top: 0;
                            display: inline-block;
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        dd {
            text-align: center;
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            word-break: keep-all;
            overflow: hidden;

            span {
                display: inline-block;
            }
        }
    }
`