import styled from "styled-components";

export const HeaderContainer = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
`

export const HeaderBx = styled.div`
    position: relative;
    padding: 20px 20px 15px;
    box-sizing: border-box;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const CloseBtn = styled.div`
`

export const ScoreBx = styled.div`
    margin-top: 5px;
    display: flex;
    justify-content: center;

    dl {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        dt {
            font-size: 14px;
            font-weight: 700;
        }

        dd {
            font-size: 24px;
            font-weight: 700;
        }
    }
`

export const LevelBx = styled.div`
    box-sizing: border-box;

    > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid blue;

        select {
            padding: 2px;
            box-sizing: border-box;
            font-size: 14px;
            font-weight: 700;
            border: none;
            background-color: transparent;
            color: blue;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
    }
`