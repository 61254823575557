import axios from "axios";
import {api} from "./apiLink";

export const setQuizScore = async (score: number, userId: number) => {
    const {data} = await axios.post(api + "/quiz/finish", {
        params: {
            score: score,
            userId: userId,
        }
    });

    return data;
}

export const getQuizRanking = async () => {
    const {data} = await axios.get(api + "/quiz/ranking");
    return data;
}

export const getMyQuizRanking = async (id: number ) => {
    const { data } = await axios.get( api + "/quiz/my-ranking", {
        params: {
            userId: id
        }
    });

    return data;
}