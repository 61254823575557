export const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};


export function getFilter(a: string, b: string) {
    if (a === '' || !a || b === '' || !b) return '';

    a = a.toLowerCase().replace(/[^a-zA-Z]/g, '');
    b = b.toLowerCase().replace(/[^a-zA-Z]/g, '');

    return a.includes(b);
}

export function getToLower(value: string) {
    if (value === '' || !value) return '';
    return value.toLowerCase().replace(/[^a-zA-Z]/g, '');
}