import styled from "styled-components";


export const Wrap = styled.div`

`

export const Inner = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 50px;
`

export const TitleBx = styled.div`
    font-size: 20px;
    font-weight: 700;
`

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`

export const InputBx = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    label {
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
    }

    input {
        width: 200px;
        padding: 7px;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid #888888;
    }
`

export const BtnBx = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`