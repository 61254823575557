import styled from "styled-components";
import {motion} from "framer-motion";


export const AnswerListBx = styled.div`
    margin-top: 50px;

    ul {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
`

export const AnswerListItem = styled.li`
    width: 50%;

    button {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        padding: 5px 0;
        position: relative;
    }
`

export const Num = styled.span<{ $isClick: boolean, $isAnswer: boolean }>`
    position: relative;
    font-size: 12px;
    font-weight: 600;
    background-color: ${p => p.$isClick ? p.$isAnswer ? '#4545ee' : 'red' : '#000000'};
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
`

export const Word = styled.span<{ $isClick: boolean, $isAnswer: boolean }>`
    font-size: 18px;
    font-weight: 700;
    color: ${p => p.$isClick ? p.$isAnswer ? '#4545ee' : 'red' : '#000000'};
    text-align: left;
    padding: 3px 5px;
    box-sizing: border-box;
    display: inline-block;
`

export const IconAnswer = styled(motion.div)`
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 3px solid #4545ee;
    left: -8px;
    top: -8px;
`