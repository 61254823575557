import * as S from "./styles";
import {useEffect, useState} from "react";
import {GetUser, SetLogin} from "../../api/user";
import {useNavigate} from "react-router-dom";
import BtnDefault from "../common/btn-default";
import {useAuthContext} from "../auth-provider";
import {isValidEmail} from "../../libs/check";
import {useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faEnvelope, faLock, faUserPlus, faSignIn} from "@fortawesome/free-solid-svg-icons";


const Login = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const back = queryParams.get('back');

    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const {user, setUserData} = useAuthContext();

    useEffect(() => {
        if (user) {
            if (!back) {
                navigate(`/`);
            } else {
                navigate(`/${back || ''}`);
            }
        }
    }, [user]);

    const onClick = async () => {
        try {
            if (!isValidEmail(email)) {
                alert("올바른 이메일을 입력해주세요.");
                return;
            }

            if (!password) {
                alert("비밀번호를 입력해주세요.");
                return;
            }

            await SetLogin(email, password);
            const data = await GetUser();
            setUserData(data);
        } catch (err: any) {
            alert(err.response.data.message);
        }
    }

    return (
        <S.Wrap>
            <S.Inner>
                <S.TitleBx>
                    <FontAwesomeIcon icon={faHouse} size="lg" style={{marginRight: '8px'}} />
                    로그인
                </S.TitleBx>
                <S.InputContainer>
                    <S.InputBx>
                        <label htmlFor="">
                            <FontAwesomeIcon icon={faEnvelope} style={{marginRight: '8px'}} />
                            이메일
                        </label>
                        <input
                            type="text"
                            placeholder={'example@email.com'}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </S.InputBx>
                    <S.InputBx>
                        <label htmlFor="">
                            <FontAwesomeIcon icon={faLock} style={{marginRight: '8px'}} />
                            비밀번호
                        </label>
                        <input
                            type="password"
                            placeholder='******'
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </S.InputBx>
                </S.InputContainer>

                <S.BtnBx>
                    <BtnDefault onClick={onClick}>
                        <FontAwesomeIcon icon={faSignIn} style={{marginRight: '8px'}} />
                        로그인
                    </BtnDefault>
                    <BtnDefault type="red" link="/signup">
                        <FontAwesomeIcon icon={faUserPlus} style={{marginRight: '8px'}} />
                        회원가입
                    </BtnDefault>
                </S.BtnBx>
            </S.Inner>
        </S.Wrap>
    )


}

export default Login;