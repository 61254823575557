import * as S from "./styles";
import {useCallback, useEffect, useState} from "react";
import {AnimatePresence} from "framer-motion";
import SoundButton from "../../sentence/sound-button";
import {getFilter} from "../../../libs/check";
import {getChatGPT} from "../../../api/chatgptapi";
import PopupLoading from "../../sentence/popup-loading";
import {useAuthContext} from "../../auth-provider";
import {useNavigate} from "react-router-dom";
import {setQuizScore} from "../../../api/quiz";
import QuizHeader from "./quiz-header";
import AnswerList from "./answer-list";
import {getRandomAnswer, getRandomWordLevel} from "../../../libs/word";
import QuizNextBtn from "./quiz-next-btn";
import QuizSentence from "./quiz-sentence";

const QuizPlay = () => {
    const [loadingMessage, setLoadingMessage] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    const [level, setLevel] = useState<string>('lv1');

    const [word, setWord] = useState<string>('시작합니다.');
    const [sentence, setSentence] = useState<any[]>([]);
    const [translation, setTranslation] = useState<string>('');
    const [soundText, setSoundText] = useState<string>('');

    const [rKey, setRandomKey] = useState<string>('');
    const [answerList, setAnswerList] = useState<any[]>();
    const [isClick, setClick] = useState<boolean>(false);
    const [score, setScore] = useState<number>(0);

    const {user} = useAuthContext();
    const navigate = useNavigate();

    const sendMessage = async () => {
        if (isLoading) return;

        const randomWord = getRandomWordLevel(level);

        setLoadingMessage('퀴즈를 만드는 중입니다.');
        setLoading(true);

        const question = `'${randomWord}' 단어로 초보 수준의 원어민 영어 문장 만들어. JSON으로 출력: w(단어), n(뜻), s(문장), t(문장 뜻)`;

        let chat = await getChatGPT(question);
        chat = JSON.parse(chat.choices[0].message.content);

        setLoading(false);

        if (!chat) return;

        const {w, n, s, t} = chat as any;

        if (w === '' || n === '' || t === '' || s === '' || typeof s !== 'string') {
            alert('다시 시도해주세요.');
            return;
        }

        setWord(w);

        const quizWordList: any[] = [];
        s.toString().split(' ').forEach((item) => {
            quizWordList.push({
                quizWord: item,
                isAnswer: getFilter(item, randomWord)
            });
        });

        setSentence(quizWordList);
        setSoundText(s);
        setTranslation(t);

        setRandomKey('' + Math.random() * 1000);

        const aList = getRandomAnswer(randomWord);
        setAnswerList(aList);
        setClick(true);
    };

    const [scores] = useState<any>({
        "lv1": 10,
        "lv2": 15,
        "lv3": 20,
    });

    const changeLevel = useCallback((lv: string) => {
        setClick(false);

        setLevel(lv);
        sendMessage();
    }, []);

    const onClickAnswer = useCallback((word: { word: string, isAnswer: boolean }) => {
        if (!isClick) return;

        const copyList = sentence.concat();
        copyList.map((item) => item.isAnswer = false);

        const s = scores[level];

        setSentence(copyList);
        setScore((v) => word.isAnswer ? v + s : v + -s);

        setClick(false);
    }, [answerList, sentence, isClick, level]);

    useEffect(() => {
        sendMessage();
    }, []);

    const setPostScore = async () => {
        if (!user) {
            if (window.confirm("[로그인]하고 지금 바로 랭킹에 도전하세요.")) {
                navigate("/login?back=quiz/info");
            }else{
                navigate("/ranking");
            }
        } else {
            try {
                await setQuizScore(score, +user.id);
                navigate("/ranking");
            } catch (err) {
                console.log(err);
            }
        }
    }

    const onClickClose = useCallback(() => {
        setPostScore();
    }, [user, score]);

    return (
        <>
            <S.Container>
                <QuizHeader onClickClose={onClickClose} score={score} changeLevel={changeLevel}/>
                <S.WordBx>
                    <AnimatePresence>
                        {
                            isLoading && <S.LoadingBx>
                                <PopupLoading message={loadingMessage}/>
                            </S.LoadingBx>
                        }
                    </AnimatePresence>
                </S.WordBx>

                <AnimatePresence mode={'wait'}>
                    <S.SentenceBx key={`sentence-${rKey}`}>
                        <div>
                            <QuizSentence rKey={rKey} sentence={sentence} word={word} translation={translation}/>
                        </div>

                        <div>
                            <AnswerList answerList={answerList} onClickAnswer={onClickAnswer}/>
                        </div>

                        {
                            !isClick && <>
                                {
                                    soundText && <SoundButton soundText={soundText}/>
                                }
                                <QuizNextBtn
                                    answerList={answerList}
                                    sendMessage={sendMessage}
                                    onClose={onClickClose}
                                />
                            </>
                        }
                    </S.SentenceBx>
                </AnimatePresence>
            </S.Container>
        </>
    )
}


export default QuizPlay;