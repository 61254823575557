import styled from "styled-components";

export const Bx = styled.div<{ $type: string }>`
    a, button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        font-size: 14px;
        font-weight: 700;
        border-radius: 10px;
        background-color: ${p => p.$type === "BLUE" ? '#4545ee' : 'red'};
        color: #ffffff;
        transition: background-color 0.3s linear;
        min-width: 150px;
    }
`;




