export const WordListData = [
    {
        groupId: "101",
        list: [
            {
                title: '기초 영단어 1-1',
                detailId: "101-01",
            },
            {
                title: '기초 영단어 1-2',
                detailId: "101-02",
            },
            {
                title: '기초 영단어 1-3',
                detailId: "101-03",
            },
            {
                title: '기초 영단어 1-4',
                detailId: "101-04",
            },
            {
                title: '기초 영단어 1-5',
                detailId: "101-05",
            },
            {
                title: '기초 영단어 1-6',
                detailId: "101-06",
            },
            {
                title: '기초 영단어 1-7',
                detailId: "101-07",
            },
            {
                title: '기초 영단어 1-8',
                detailId: "101-08",
            },
            {
                title: '기초 영단어 1-9',
                detailId: "101-09",
            },
            {
                title: '기초 영단어 1-10',
                detailId: "101-10",
            },
            {
                title: '기초 영단어 1-11',
                detailId: "101-11",
            },
            {
                title: '기초 영단어 1-12',
                detailId: "101-12",
            },
            {
                title: '기초 영단어 1-13',
                detailId: "101-13",
            },
            {
                title: '기초 영단어 1-14',
                detailId: "101-14",
            },
            {
                title: '기초 영단어 1-15',
                detailId: "101-15",
            },
        ]
    },
    {
        groupId: "102",
        list: [
            {
                title: '기초 영단어 2-1',
                detailId: "102-01",
            },
            {
                title: '기초 영단어 2-2',
                detailId: "102-02",
            },
            {
                title: '기초 영단어 2-3',
                detailId: "102-03",
            },
            {
                title: '기초 영단어 2-4',
                detailId: "102-04",
            },
            {
                title: '기초 영단어 2-5',
                detailId: "102-05",
            },
            {
                title: '기초 영단어 2-6',
                detailId: "102-06",
            },
            {
                title: '기초 영단어 2-7',
                detailId: "102-07",
            },
            {
                title: '기초 영단어 2-8',
                detailId: "102-08",
            },
            {
                title: '기초 영단어 2-9',
                detailId: "102-09",
            },
            {
                title: '기초 영단어 2-10',
                detailId: "102-10",
            },
            {
                title: '기초 영단어 2-11',
                detailId: "102-11",
            },
            {
                title: '기초 영단어 2-12',
                detailId: "102-12",
            },
            {
                title: '기초 영단어 2-13',
                detailId: "102-13",
            },
            {
                title: '기초 영단어 2-14',
                detailId: "102-14",
            },
            {
                title: '기초 영단어 2-15',
                detailId: "102-15",
            },
        ]
    },
    {
        groupId: "201",
        list: [
            {
                title: "중급 영단어 1-1",
                detailId: "201-01",
            },
            {
                title: "중급 영단어 1-2",
                detailId: "201-02",
            },
            {
                title: "중급 영단어 1-3",
                detailId: "201-03",
            },
            {
                title: "중급 영단어 1-4",
                detailId: "201-04",
            },
            {
                title: "중급 영단어 1-5",
                detailId: "201-05",
            },
            {
                title: "중급 영단어 1-6",
                detailId: "201-06",
            },
            {
                title: "중급 영단어 1-7",
                detailId: "201-07",
            },
            {
                title: "중급 영단어 1-8",
                detailId: "201-08",
            },
            {
                title: "중급 영단어 1-9",
                detailId: "201-09",
            },
            {
                title: "중급 영단어 1-10",
                detailId: "201-10",
            },
            {
                title: "중급 영단어 1-11",
                detailId: "201-11",
            },
            {
                title: "중급 영단어 1-12",
                detailId: "201-12",
            },
            {
                title: "중급 영단어 1-13",
                detailId: "201-13",
            },
            {
                title: "중급 영단어 1-14",
                detailId: "201-14",
            },
            {
                title: "중급 영단어 1-15",
                detailId: "201-15",
            },
        ]
    },
    {
        groupId: "202",
        list: [
            {
                title: "중급 영단어 2-1",
                detailId: "202-01",
            },
            {
                title: "중급 영단어 2-2",
                detailId: "202-02",
            },
            {
                title: "중급 영단어 2-3",
                detailId: "202-03",
            },
            {
                title: "중급 영단어 2-4",
                detailId: "202-04",
            },
            {
                title: "중급 영단어 2-5",
                detailId: "202-05",
            },
            {
                title: "중급 영단어 2-6",
                detailId: "202-06",
            },
            {
                title: "중급 영단어 2-7",
                detailId: "202-07",
            },
            {
                title: "중급 영단어 2-8",
                detailId: "202-08",
            },
            {
                title: "중급 영단어 2-9",
                detailId: "202-09",
            },
            {
                title: "중급 영단어 2-10",
                detailId: "202-10",
            },
            {
                title: "중급 영단어 2-11",
                detailId: "202-11",
            },
            {
                title: "중급 영단어 2-12",
                detailId: "202-12",
            },
            {
                title: "중급 영단어 2-13",
                detailId: "202-13",
            },
            {
                title: "중급 영단어 2-14",
                detailId: "202-14",
            },
        ]
    },
    {
        groupId: "301",
        list: [
            {
                title: "고급 영단어 1-1",
                detailId: "301-01",
            },
            {
                title: "고급 영단어 1-2",
                detailId: "301-02",
            },
            {
                title: "고급 영단어 1-3",
                detailId: "301-03",
            },
            {
                title: "고급 영단어 1-4",
                detailId: "301-04",
            },
            {
                title: "고급 영단어 1-5",
                detailId: "301-05",
            },
            {
                title: "고급 영단어 1-6",
                detailId: "301-06",
            },
            {
                title: "고급 영단어 1-7",
                detailId: "301-07",
            },
            {
                title: "고급 영단어 1-8",
                detailId: "301-08",
            },
            {
                title: "고급 영단어 1-9",
                detailId: "301-09",
            },
            {
                title: "고급 영단어 1-10",
                detailId: "301-10",
            },
            {
                title: "고급 영단어 1-11",
                detailId: "301-11",
            },
            {
                title: "고급 영단어 1-12",
                detailId: "301-12",
            },
            {
                title: "고급 영단어 1-13",
                detailId: "301-13",
            },
            {
                title: "고급 영단어 1-14",
                detailId: "301-14",
            },
            {
                title: "고급 영단어 1-15",
                detailId: "301-15",
            },
        ]
    },
    {
        groupId: "302",
        list: [
            {
                title: "고급 영단어 3-1",
                detailId: "302-01",
            },
            {
                title: "고급 영단어 3-2",
                detailId: "302-02",
            },
            {
                title: "고급 영단어 3-3",
                detailId: "302-03",
            },
            {
                title: "고급 영단어 3-4",
                detailId: "302-04",
            },
            {
                title: "고급 영단어 3-5",
                detailId: "302-05",
            },
            {
                title: "고급 영단어 3-6",
                detailId: "302-06",
            },
            {
                title: "고급 영단어 3-7",
                detailId: "302-07",
            },
            {
                title: "고급 영단어 3-8",
                detailId: "302-08",
            },
            {
                title: "고급 영단어 3-9",
                detailId: "302-09",
            },
            {
                title: "고급 영단어 3-10",
                detailId: "302-10",
            },
            {
                title: "고급 영단어 3-11",
                detailId: "302-11",
            },
            {
                title: "고급 영단어 3-12",
                detailId: "302-12",
            },
            {
                title: "고급 영단어 3-13",
                detailId: "302-13",
            },
            {
                title: "고급 영단어 3-14",
                detailId: "302-14",
            },
            {
                title: "고급 영단어 3-15",
                detailId: "302-15",
            },
            {
                title: "고급 영단어 3-16",
                detailId: "302-16",
            },
        ]
    }
]