import Signup from "../../component/signup";
import Layout from "../../component/layout";

const SignupPage = () => {
    return(
        <Layout>
            <Signup />
        </Layout>
    )
}

export default SignupPage;