import * as S from "./styles";
import {Link} from "react-router-dom";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {AnimatePresence} from "framer-motion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faBook, faGamepad, faChartLine} from "@fortawesome/free-solid-svg-icons";

const HeaderData = [
    {
        title: '문장',
        represent: '/sentence',
        link: "/sentence"
    },
    {
        title: '퀴즈',
        represent: '/quiz',
        link: "/quiz/info"
    },
    {
        title: '단어',
        represent: '/memorize',
        link: "/memorize/word"
    },
    {
        title: '정보',
        represent: '/info',
        link: "/info"
    },
]

const BottomMenu = () => {
    const location = useLocation();
    const [depthList] = useState<string[]>(
        location.pathname.split("/")
    );
    const [isView, setView] = useState<boolean>(true);

    const [depth1, setDepth1] = useState<string>(`/${depthList[1]}`);

    const [isShowSub, setShowSub] = useState<boolean[]>(
        Array.from({length: HeaderData.length})
    );

    useEffect(() => {
        const url = location.pathname.split("/");

        if (url[1] === 'quiz' && url[2] === 'play') {
            setView(false);
        } else {
            setView(true);
        }

        setShowSub([...isShowSub].map(() => false));
        setDepth1(`/${url[1]}`);

        window.scrollTo(0, 0);
    }, [location]);

    return (
        <AnimatePresence>
            {
                isView &&
                <S.Wrap
                    initial={{y: '100%'}}
                    animate={{y: 0}}
                    exit={{y: '100%'}}
                    transition={{
                        duration: 0.5,
                        ease: 'easeOut',
                    }}
                >
                    <S.Inner>
                        <S.Container>
                            <ul>
                                {
                                    HeaderData.map((item, idx) => (
                                        <S.MenuBx key={`header-${idx}`} $isIn={depth1 === item.represent}>
                                            <Link to={item.link} style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                gap: '5px'
                                            }}>
                                                <FontAwesomeIcon
                                                    icon={
                                                        item.title === '문장' ? faBook :
                                                            item.title === '퀴즈' ? faGamepad :
                                                                item.title === '단어' ? faChartLine :
                                                                    item.title === '정보' ? faHome :
                                                                        faHome
                                                    }
                                                    size="lg"
                                                />
                                                {item.title}
                                            </Link>
                                        </S.MenuBx>
                                    ))
                                }
                            </ul>
                        </S.Container>
                    </S.Inner>
                </S.Wrap>
            }
        </AnimatePresence>
    )
}

export default BottomMenu;