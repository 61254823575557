import styled from "styled-components";
import {motion} from "framer-motion";

export const LinkBx = styled(motion.div)`
    display: flex;
    justify-content: center;


    dl{
        dt{
            text-align: center;
            margin-bottom: 30px;
            font-size: 18px;
            font-weight: 500;
            line-height: 23px;

            span{
                font-size: 18px;
                font-weight: 700;
                text-decoration: underline;
            }
        }
        
        dd{
            text-align: center;
            a{
                font-size: 28px;
                font-weight: 700;
                color: #000000;
                transition: border-bottom 0.2s;
                display: flex;
                flex-direction: column;
                align-items: center;
                svg{
                    margin-bottom: 10px;
                }
                &:hover{
                    border-bottom: 2px solid #000000;
                }
            }
        }
    }
`