import Layout from "../../../component/layout";
import QuizPlay from "../../../component/quiz/quiz-play";
const QuizPlayPage = () => {
    return(
        <Layout>
            <QuizPlay />
        </Layout>
    )
}

export default QuizPlayPage;