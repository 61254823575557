import * as S from "./styles";
import ClipboardBx from "../common/clipboard-bx";
import { useAuthContext } from "../auth-provider";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGamepad, faChartLine, faBook } from "@fortawesome/free-solid-svg-icons";

const Info = () => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    useEffect(() => {
        if (!user) {
            // navigate("/login?back=info");
        }
    }, [user]);

    return (
        <S.Wrap style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh'
        }}>
            <S.Inner style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px',
                padding: '20px',
                backgroundColor: '#fff',
                borderRadius: '10px',
                width: '100%',
                maxWidth: '500px'
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    fontSize: '18px',
                    fontWeight: '600'
                }}>
                    <FontAwesomeIcon icon={faGamepad} style={{ color: '#4a90e2' }} />
                    <span>이름 : 영어꼬리</span>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    fontSize: '18px',
                    fontWeight: '500'
                }}>
                    <FontAwesomeIcon icon={faChartLine} style={{ color: '#4a90e2' }} />
                    <span>버전 : v0.01</span>
                </div>

                <ClipboardBx />

                <a href="mailto:kineage18@gmail.com" style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    textDecoration: 'none',
                    color: '#333',
                    padding: '15px',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '8px',
                    transition: 'all 0.3s ease',
                    fontWeight: '500'
                }}>
                    <FontAwesomeIcon icon={faBook} style={{ color: '#4a90e2' }} />
                    <div>
                        <span style={{ fontWeight: '600' }}>문의 하기</span><br />
                        <span style={{
                            fontSize: '14px',
                            color: '#666',
                            fontWeight: '400'
                        }}>(많은 피드백 부탁드립니다.)</span>
                    </div>
                </a>

                {
                    !user && <Link to={'/login'} style={{
                        textAlign: 'center',
                        marginTop: '50px',
                        width: '100%',
                        padding: '15px',
                        backgroundColor: '#4a90e2',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '16px',
                        fontWeight: '600',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease'
                    }}>
                        로그인
                    </Link>
                }
            </S.Inner>
        </S.Wrap>
    )
}

export default Info;