import styled, {keyframes} from "styled-components";
import {motion} from "framer-motion";

export const Wrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
`

export const Inner = styled(motion.div)<{ $isPlay: boolean }>`
    display: flex;
    align-items: center;
    flex-direction: column;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
        width: 30px;
        height: 30px;
        border: 1px solid ${ p => p.$isPlay ? '#9d9d9d' : '#000000' };
        overflow: hidden;
        border-radius: 100%;

        img {
            width: 17px;
            height: 17px;
            position: relative;
            transform: rotate(-90deg);
        }
    }
`

export const Animation = keyframes`
    to {
        transform: scaleY(0.1);
    }
    from {
        transform: scaleY(1);
    }
`

export const Animation2 = keyframes`
    to {
        transform: scaleY(1);
    }
    from {
        transform: scaleY(0);
    }
`

export const EffectBx = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;

    span {
        display: inline-block;
        width: 2px;
        height: 15px;
        background-color: #9d9d9d;
        transform-origin: center bottom;

        &:nth-child(1) {
            animation: ${Animation} .4s ease 0s infinite alternate;
        }

        &:nth-child(2) {
            animation: ${Animation2} .2s ease 0s infinite alternate;
        }

        &:nth-child(3) {
            animation: ${Animation} .3s ease 0s infinite alternate;
        }

        &:nth-child(4) {
            animation: ${Animation2} .4s ease 0s infinite alternate;
        }

        &:nth-child(5) {
            animation: ${Animation} .5s ease 0s infinite alternate;
        }
    }
`

export const PlayingText = styled.div<{ $isPlay: boolean }>`
    margin-top: 5px;
    font-size: 12px;
    font-weight: 700;
    
    color: ${ p => p.$isPlay ? '#9d9d9d' : '#000000' };
`