export const WordListDetailData = [
    {
        detailId: "101-01",
        list: [
            {
                "word": "hello",
                "meaning": "안녕, 안녕하세요"
            },
            {
                "word": "good-bye",
                "meaning": "안녕히 가세요"
            },
            {
                "word": "here",
                "meaning": "여기에"
            },
            {
                "word": "mom",
                "meaning": "엄마"
            },
            {
                "word": "see",
                "meaning": "보다"
            },
            {
                "word": "there",
                "meaning": "그곳에, 거기에"
            },
            {
                "word": "ok",
                "meaning": "좋아, 그래"
            },
            {
                "word": "drink",
                "meaning": "마시다"
            },
            {
                "word": "eat",
                "meaning": "먹다"
            },
            {
                "word": "hungry",
                "meaning": "배고픈"
            },
        ],
    },
    {
        detailId: "101-02",
        list: [
            {
                "word": "say",
                "meaning": "말하다"
            },
            {
                "word": "thirsty",
                "meaning": "목이마른; 갈망하는"
            },
            {
                "word": "push",
                "meaning": "밀다; 추진하다; 누르다"
            },
            {
                "word": "stand",
                "meaning": "서다, 서있다; 견뎌내다"
            },
            {
                "word": "speak",
                "meaning": "이야기하다, 말하다"
            },
            {
                "word": "sit",
                "meaning": "앉다"
            },
            {
                "word": "talk",
                "meaning": "말하다, 이야기하다, 대화하다"
            },
            {
                "word": "dad",
                "meaning": "아빠"
            },
            {
                "word": "like",
                "meaning": "좋아하다"
            },
            {
                "word": "walk",
                "meaning": "걷다, 산책하다;"
            }
        ],
    },
    {
        detailId: "101-03",
        list: [
            {
                "word": "word",
                "meaning": "단어, 낱말, 말; 약속"
            },
            {
                "word": "wish",
                "meaning": "바라다, 희망하다"
            },
            {
                "word": "tell",
                "meaning": "말하다, 이야기하다"
            },
            {
                "word": "turn",
                "meaning": "돌다, 회전하다"
            },
            {
                "word": "touch",
                "meaning": "만지다; 감동시키다;"
            },
            {
                "word": "friend",
                "meaning": "친구"
            },
            {
                "word": "smile",
                "meaning": "미소 짓다;"
            },
            {
                "word": "find",
                "meaning": "찾다, 발견하다"
            },
            {
                "word": "child",
                "meaning": "아이, 어린이"
            },
            {
                "word": "wait",
                "meaning": "기다리다"
            }
        ],
    },
    {
        detailId: "101-04",
        list: [
            {
                "word": "throw",
                "meaning": "던지다, 팽개치다; 발사하다;"
            },
            {
                "word": "wear",
                "meaning": "입고 있다; 낡게 하다"
            },
            {
                "word": "watch",
                "meaning": "보다, 지켜보다"
            },
            {
                "word": "stay",
                "meaning": "머무르다, 체류하다"
            },
            {
                "word": "die",
                "meaning": "죽다"
            },
            {
                "word": "look",
                "meaning": "보다, 보이다; 찾다; 주목하다"
            },
            {
                "word": "night",
                "meaning": "밤, 야간"
            },
            {
                "word": "use",
                "meaning": "쓰다, 사용하다"
            },
            {
                "word": "stop",
                "meaning": "멈추다, 중단하다"
            },
            {
                "word": "feel",
                "meaning": "느끼다"
            },
        ],
    },
    {
        detailId: "101-05",
        list: [
            {
                "word": "sad",
                "meaning": "슬픈"
            },
            {
                "word": "wet",
                "meaning": "젖은"
            },
            {
                "word": "sleep",
                "meaning": "잠, 수면; 자다"
            },
            {
                "word": "hi",
                "meaning": "안녕"
            },
            {
                "word": "please",
                "meaning": "제발, 부디"
            },
            {
                "word": "sorry",
                "meaning": "미안한, 안된, 유감스러운"
            },
            {
                "word": "snow",
                "meaning": "눈"
            },
            {
                "word": "thank",
                "meaning": "감사하다, 고마워하다"
            },
            {
                "word": "rain",
                "meaning": "비"
            },
            {
                "word": "hey",
                "meaning": "어이, 이봐"
            }
        ],
    },
    {
        detailId: "101-06",
        list: [
            {
                "word": "mignight",
                "meaning": "자정, 한밤중"
            },
            {
                "word": "run",
                "meaning": "달리다; 운영하다; 작동하다; 개최하다;"
            },
            {
                "word": "sometimes",
                "meaning": "때때로, 가끔"
            },
            {
                "word": "evening",
                "meaning": "저녁"
            },
            {
                "word": "today",
                "meaning": "오늘, 오늘날에, 현재"
            },
            {
                "word": "shop",
                "meaning": "가게, 상점"
            },
            {
                "word": "morning",
                "meaning": "아침, 오전"
            },
            {
                "word": "afternoon",
                "meaning": "오후"
            },
            {
                "word": "tonight",
                "meaning": "\b오늘 밤에; 오늘 밤"
            },
            {
                "word": "tomorrow",
                "meaning": "내일"
            }
        ],
    },
    {
        detailId: "101-07",
        list: [
            {
                "word": "gray",
                "meaning": "회색"
            },
            {
                "word": "brown",
                "meaning": "갈색"
            },
            {
                "word": "jump",
                "meaning": "뛰다, 점프하다"
            },
            {
                "word": "blue",
                "meaning": "파란색"
            },
            {
                "word": "clothes",
                "meaning": "옷, 의복"
            },
            {
                "word": "purple",
                "meaning": "보라색, 자주색, 퍼플"
            },
            {
                "word": "orange",
                "meaning": "오렌지"
            },
            {
                "word": "know",
                "meaning": "알다"
            },
            {
                "word": "pink",
                "meaning": "핑크색, 분홍색"
            },
            {
                "word": "green",
                "meaning": null
            }
        ],
    },
    {
        detailId: "101-08",
        list: [
            {
                "word": "later",
                "meaning": "나중에, 후에"
            },
            {
                "word": "good",
                "meaning": "좋은"
            },
            {
                "word": "last",
                "meaning": "지난, 가장 최근의; 마지막의"
            },
            {
                "word": "now",
                "meaning": "지금, 이제"
            },
            {
                "word": "cook",
                "meaning": "요리하다"
            },
            {
                "word": "lunch",
                "meaning": "점심"
            },
            {
                "word": "next",
                "meaning": "다음의"
            },
            {
                "word": "breakfast",
                "meaning": "아침식사"
            },
            {
                "word": "yesterday",
                "meaning": "어제"
            },
            {
                "word": "dinner",
                "meaning": "저녁식사, 저녁"
            }
        ],
    },
    {
        detailId: "101-09",
        list: [
            {
                "word": "fine",
                "meaning": "좋은, 훌륭한"
            },
            {
                "word": "meet",
                "meaning": "만나다; 충족시키다"
            },
            {
                "word": "buy",
                "meaning": "사다, 구입하다"
            },
            {
                "word": "red",
                "meaning": "빨간색"
            },
            {
                "word": "moon",
                "meaning": "달"
            },
            {
                "word": "white",
                "meaning": "흰색"
            },
            {
                "word": "color",
                "meaning": "색"
            },
            {
                "word": "week",
                "meaning": "주, 일주일"
            },
            {
                "word": "in",
                "meaning": "~안에, ~에서, ~으로, ~후에"
            },
            {
                "word": "black",
                "meaning": "검은색"
            }
        ],
    },
    {
        detailId: "101-10",
        list: [
            {
                "word": "worry",
                "meaning": "걱정하다; 괴롭히다"
            },
            {
                "word": "read",
                "meaning": "읽다, 독서하다"
            },
            {
                "word": "study",
                "meaning": "공부, 연구"
            },
            {
                "word": "end",
                "meaning": "끝, 마지막"
            },
            {
                "word": "wash",
                "meaning": "씻다, 세탁하다, 세척하다"
            },
            {
                "word": "call",
                "meaning": "부르다, 전화하다"
            },
            {
                "word": "name",
                "meaning": "이름"
            },
            {
                "word": "swim",
                "meaning": "수영하다, 헤엄치다"
            },
            {
                "word": "work",
                "meaning": "일하다, 작업하다"
            },
            {
                "word": "write",
                "meaning": "쓰다, 작성하다"
            }
        ],
    },
    {
        detailId: "101-11",
        list: [
            {
                "word": "share",
                "meaning": "함께 쓰다, 공유하다, 나누다"
            },
            {
                "word": "leave",
                "meaning": "떠나다; 남기다; 맡기다; 출발하다"
            },
            {
                "word": "start",
                "meaning": "시작하다, 출발하다; 시작"
            },
            {
                "word": "where",
                "meaning": "어디에, 어디로"
            },
            {
                "word": "show",
                "meaning": "보여주다"
            },
            {
                "word": "when",
                "meaning": "언제, ~할 때"
            },
            {
                "word": "what",
                "meaning": "무엇; 어떤 것; 정말, 얼마나"
            },
            {
                "word": "who",
                "meaning": "누구"
            },
            {
                "word": "open",
                "meaning": "열다"
            },
            {
                "word": "hear",
                "meaning": "듣다, 들리다"
            },
        ],
    },
    {
        detailId: "101-12",
        list: [
            {
                "word": "long",
                "meaning": "긴, 오래; 오랫동안"
            },
            {
                "word": "cool",
                "meaning": "시원한, 서늘한; 멋진"
            },
            {
                "word": "big",
                "meaning": "큰, 크게"
            },
            {
                "word": "top",
                "meaning": "맨 위, 꼭대기, 정상; 팽이;"
            },
            {
                "word": "hot",
                "meaning": "뜨거운; 매운"
            },
            {
                "word": "warm",
                "meaning": "따뜻한"
            },
            {
                "word": "large",
                "meaning": "큰"
            },
            {
                "word": "few",
                "meaning": "거의 없는, 소수의, 몇몇의"
            },
            {
                "word": "late",
                "meaning": "늦은"
            },
            {
                "word": "again",
                "meaning": "다시, 다시 한번"
            },
        ],
    },
    {
        detailId: "101-13",
        list: [
            {
                "word": "which",
                "meaning": "어느, 어떤; 어떤 사람, 어느 것"
            },
            {
                "word": "why",
                "meaning": "왜"
            },
            {
                "word": "many",
                "meaning": "많은"
            },
            {
                "word": "some",
                "meaning": "조금, 약간의"
            },
            {
                "word": "little",
                "meaning": "작은; 어린;"
            },
            {
                "word": "great",
                "meaning": "큰, 거대한; 좋은; 위대한"
            },
            {
                "word": "how",
                "meaning": "어떻게; 얼마나"
            },
            {
                "word": "book",
                "meaning": "예약하다; 책, 도서"
            },
            {
                "word": "nice",
                "meaning": "좋은, 즐거운; 친절한"
            },
            {
                "word": "much",
                "meaning": "많은; 매우, 너무"
            }
        ],
    },
    {
        detailId: "101-14",
        list: [
            {
                "word": "exit",
                "meaning": "출구; 나가다"
            },
            {
                "word": "care",
                "meaning": "돌봄, 보살핌"
            },
            {
                "word": "drive",
                "meaning": "운전하다"
            },
            {
                "word": "cry",
                "meaning": "울다, 외치다, 울부짖다"
            },
            {
                "word": "brush",
                "meaning": "솔질하다, 닦다"
            },
            {
                "word": "fly",
                "meaning": "날다, 비행하다"
            },
            {
                "word": "come",
                "meaning": "(~쪽으로) 오다, 움직이다"
            },
            {
                "word": "glad",
                "meaning": "기쁜, 반가운"
            },
            {
                "word": "cut",
                "meaning": "삭감하다, 일하하다; 자르다"
            },
            {
                "word": "dance",
                "meaning": "춤추다; 춤, 무용, 댄스"
            },
        ]
    },
    {
        detailId: "101-15",
        list: [
            {
                "word": "different",
                "meaning": "다른, 차이가 나는"
            },
            {
                "word": "together",
                "meaning": "함께, 같이"
            },
            {
                "word": "small",
                "meaning": "작은, 소규모의"
            },
            {
                "word": "cold",
                "meaning": "추운, 차가운; 추위; 감기"
            },
            {
                "word": "same",
                "meaning": "같은, 동일한"
            },
            {
                "word": "old",
                "meaning": "나이든; 오래된"
            },
            {
                "word": "young",
                "meaning": "젋은, 어린;"
            },
            {
                "word": "tall",
                "meaning": "키가 큰, 높은"
            },
            {
                "word": "new",
                "meaning": "새로운"
            },
            {
                "word": "back",
                "meaning": "뒤쪽; 등; 뒤의"
            }
        ]
    },
    {
        detailId: "102-01",
        list: [
            {
                "word": "sport",
                "meaning": "스포츠, 운동"
            },
            {
                "word": "exercise",
                "meaning": "운동"
            },
            {
                "word": "game",
                "meaning": "게임, 오락; 경기"
            },
            {
                "word": "baseball",
                "meaning": "야구"
            },
            {
                "word": "volleyball",
                "meaning": "배구"
            },
            {
                "word": "tennis",
                "meaning": "테니스"
            },
            {
                "word": "soccer",
                "meaning": "축구"
            },
            {
                "word": "golf",
                "meaning": "골프"
            },
            {
                "word": "gym",
                "meaning": "체육관; 체육"
            },
            {
                "word": "catch",
                "meaning": "잡다; 이해하다"
            },
        ]
    },
    {
        detailId: "102-02",
        list: [
            {
                "word": "heart",
                "meaning": "심장; 마음"
            },
            {
                "word": "tooth",
                "meaning": "이, 치아"
            },
            {
                "word": "skin",
                "meaning": "피부; 가죽"
            },
            {
                "word": "from",
                "meaning": "~에서, ~으로부터, ~부터"
            },
            {
                "word": "music",
                "meaning": "음악"
            },
            {
                "word": "card",
                "meaning": "카드; 두꺼운 종이"
            },
            {
                "word": "sure",
                "meaning": "확신하는; 그럼요, 물론이죠"
            },
            {
                "word": "listen",
                "meaning": "듣다"
            },
            {
                "word": "try",
                "meaning": "노력하다, 시도하다"
            },
            {
                "word": "for",
                "meaning": "~위해서, ~에 대하여; ~동안; ~행의, ~을 위하여"
            }
        ]
    },
    {
        detailId: "102-03",
        list: [
            {
                "word": "but",
                "meaning": "그러나, 하지만"
            },
            {
                "word": "answer",
                "meaning": "대답; 해답"
            },
            {
                "word": "right",
                "meaning": "권리; 옳은; 맞는, 정확한; 오른쪽의, 즉시, 바로"
            },
            {
                "word": "learn",
                "meaning": "배우다"
            },
            {
                "word": "never",
                "meaning": "결코 ~하지 않는다"
            },
            {
                "word": "or",
                "meaning": "또는"
            },
            {
                "word": "and",
                "meaning": "그리고"
            },
            {
                "word": "because",
                "meaning": "~때문에, 왜냐하면"
            },
            {
                "word": "then",
                "meaning": "그때, 그 다음에, 그 후에; 그 당시의"
            },
            {
                "word": "country",
                "meaning": "국가, 나라; 시골"
            },
        ]
    },
    {
        detailId: "102-04",
        list: [
            {
                "word": "explain",
                "meaning": "설명하다"
            },
            {
                "word": "pain",
                "meaning": "아픔, 통증, 고통"
            },
            {
                "word": "trouble",
                "meaning": "곤란; 병 문제, 근심; 괴롭히다"
            },
            {
                "word": "remember",
                "meaning": "기억하다"
            },
            {
                "word": "cooking",
                "meaning": "요리, 음식준비"
            },
            {
                "word": "snack",
                "meaning": "간식"
            },
        ]
    },
    {
        detailId: "102-05",
        list: [
            {
                "word": "once",
                "meaning": "한 번; 한때; ~하면, ~하자마자"
            },
            {
                "word": "life",
                "meaning": "삶, 생명"
            },
            {
                "word": "enjoy",
                "meaning": "즐기다, 누리다"
            },
            {
                "word": "front",
                "meaning": "앞, 정면; 앞의, 정면의"
            },
            {
                "word": "change",
                "meaning": "바꾸다, 달라지다, 수정하다"
            },
            {
                "word": "live",
                "meaning": "살다"
            },
            {
                "word": "question",
                "meaning": "질문, 의문"
            },
            {
                "word": "ready",
                "meaning": "준비된; 준비하다"
            },
            {
                "word": "left",
                "meaning": "왼쪽의"
            },
            {
                "word": "age",
                "meaning": "나이, 연령"
            },
        ]
    },
    {
        detailId: "102-06",
        list: [
            {
                "word": "comfortable",
                "meaning": "편안한, 안락한"
            },
            {
                "word": "fall",
                "meaning": "떨어지다. 하락하다"
            },
            {
                "word": "cute",
                "meaning": "귀여운, 예쁜"
            },
            {
                "word": "finish",
                "meaning": "끝내다, 마치다; 끝"
            },
            {
                "word": "at",
                "meaning": "~에, ~에서, ~으로"
            },
            {
                "word": "begin",
                "meaning": "시작하다, 출발하다"
            },
            {
                "word": "any",
                "meaning": "무슨, 누구든, 얼마간의, 어떤"
            },
            {
                "word": "easy",
                "meaning": "쉬운; 편안한; 쉽게"
            },
        ]
    },
    {
        detailId: "102-07",
        list: [
            {
                "word": "list",
                "meaning": "목록, 명단"
            },
            {
                "word": "understand",
                "meaning": "이해하다, 알아듣다"
            },
            {
                "word": "gift",
                "meaning": "선물; 재능"
            },
            {
                "word": "health",
                "meaning": "건강"
            },
            {
                "word": "pretty",
                "meaning": "예쁜, 귀여운"
            },
            {
                "word": "world",
                "meaning": "세계"
            },
            {
                "word": "year",
                "meaning": "해, 년; 나이"
            },
            {
                "word": "forget",
                "meaning": "잊다"
            },
            {
                "word": "visit",
                "meaning": "방문하다"
            },
            {
                "word": "welcome",
                "meaning": "환영하다; 반가운"
            },
        ]
    },
    {
        detailId: "102-08",
        list: [
            {
                "word": "lay",
                "meaning": "눕다; 놓다; 낳다"
            },
            {
                "word": "comb",
                "meaning": "빗; 빗질하다"
            },
            {
                "word": "fruit",
                "meaning": "과일, 열매"
            },
            {
                "word": "own",
                "meaning": "자기 자신의; 스스로 하는; 고유한"
            },
            {
                "word": "nap",
                "meaning": "낮잠"
            },
            {
                "word": "cash",
                "meaning": "현금"
            },
            {
                "word": "plant",
                "meaning": "식물; 공장"
            },
            {
                "word": "lie",
                "meaning": "누워있다; 놓여있다; 거짓말"
            },
            {
                "word": "relax",
                "meaning": "휴식을 취하다"
            },
            {
                "word": "teach",
                "meaning": "가르치다"
            },
        ]
    },
    {
        detailId: "102-09",
        list: [
            {
                "word": "ride",
                "meaning": "타다, 타고 가다"
            },
            {
                "word": "bright",
                "meaning": "밝은, 빛나는; 영리한"
            },
            {
                "word": "spend",
                "meaning": "쓰다, 소비하다"
            },
            {
                "word": "wonderful",
                "meaning": "놀랄만한, 훌륭한, 멋진, 경이로운"
            },
            {
                "word": "awake",
                "meaning": "깨우다"
            },
            {
                "word": "rest",
                "meaning": "휴식; 나머지; 쉬다, 휴식을 취하다"
            },
            {
                "word": "true",
                "meaning": "사실인, 참인, 맞는, 진짜의"
            },
            {
                "word": "message",
                "meaning": "메세지, 메모"
            },
            {
                "word": "beautiful",
                "meaning": "아름다운, 멋진"
            },
        ]
    },
    {
        detailId: "102-10",
        list: [
            {
                "word": "ticket",
                "meaning": "표, 입장권"
            },
            {
                "word": "tour",
                "meaning": "여행, 관광, 순회, 견학"
            },
            {
                "word": "passport",
                "meaning": "여권"
            },
            {
                "word": "trip",
                "meaning": "여행"
            },
            {
                "word": "wood",
                "meaning": "나무, 목재"
            },
            {
                "word": "travel",
                "meaning": "여행하다, 이동하다, 여행"
            },
            {
                "word": "leaf",
                "meaning": "잎"
            },
            {
                "word": "animal",
                "meaning": "동물, 짐승"
            },
            {
                "word": "fish",
                "meaning": "생선; 낚시하다"
            },
            {
                "word": "flower",
                "meaning": "꽃"
            },
        ]
    },
    {
        detailId: "102-11",
        list: [
            {
                "word": "collect",
                "meaning": "모으다, 수집하다"
            },
            {
                "word": "enough",
                "meaning": "충분한; 충분히"
            },
            {
                "word": "kindergarten",
                "meaning": "유치원"
            },
            {
                "word": "might",
                "meaning": "may의 과거, ~할지도 모른다, ~지도 모른다"
            },
            {
                "word": "free",
                "meaning": "무료의, 자유의"
            },
            {
                "word": "heavy",
                "meaning": "무거운"
            },
            {
                "word": "must",
                "meaning": "~해야 한다; ~임에 틀림없다"
            },
            {
                "word": "hospital",
                "meaning": "병원"
            },
            {
                "word": "important",
                "meaning": "중요한"
            },
            {
                "word": "dark",
                "meaning": "어두운, 캄캄한; 어둠"
            },
        ]
    },
    {
        detailId: "102-12",
        list: [
            {
                "word": "draw",
                "meaning": "그리다; 끌다; 결론을 내다"
            },
            {
                "word": "store",
                "meaning": "가게, 상점"
            },
            {
                "word": "shout",
                "meaning": "외치다, 큰 소리를 내다"
            },
            {
                "word": "waste",
                "meaning": "낭비하다; 낭비; 쓰레기, 폐기물"
            },
            {
                "word": "sell",
                "meaning": "팔다"
            },
            {
                "word": "let",
                "meaning": "~하게 해주다, ~하도록 두다, 허락하다"
            },
            {
                "word": "rich",
                "meaning": "부자의, 부유한, 풍부한"
            },
            {
                "word": "lady",
                "meaning": "여성"
            },
            {
                "word": "laugh",
                "meaning": "웃다, 웃음"
            },
            {
                "word": "soft",
                "meaning": "부드러운, 푹신한"
            },
        ]
    },
    {
        detailId: "102-13",
        list: [
            {
                "word": "wrong",
                "meaning": "틀린, 잘못된; 나쁜"
            },
            {
                "word": "hard",
                "meaning": "단단한; 어려운; 열심히"
            },
            {
                "word": "most",
                "meaning": "대부분의, 최대의, 가장 많은"
            },
            {
                "word": "half",
                "meaning": "반, 절반"
            },
            {
                "word": "dangerous",
                "meaning": "위험한"
            },
            {
                "word": "flag",
                "meaning": "깃발"
            },
            {
                "word": "could",
                "meaning": "~할 수 있었다"
            },
            {
                "word": "may",
                "meaning": "~일지도 모른다, ~해도 좋다"
            },
            {
                "word": "more",
                "meaning": "더 많은; 더"
            },
        ]
    },
    {
        detailId: "102-14",
        list: [
            {
                "word": "silent",
                "meaning": "침묵의, 말을 안하는, 조용한"
            },
            {
                "word": "weather",
                "meaning": "날씨"
            },
            {
                "word": "wake",
                "meaning": "깨다, 깨우다"
            },
            {
                "word": "shadow",
                "meaning": "그림자, 어둠의, 그늘"
            },
            {
                "word": "quiet",
                "meaning": "조용한"
            },
            {
                "word": "strange",
                "meaning": "이상한, 낯선"
            },
            {
                "word": "simple",
                "meaning": "간단한, 단순한"
            },
            {
                "word": "terrible",
                "meaning": "끔찍한, 소름끼치는"
            },
            {
                "word": "will",
                "meaning": "~할 것이다"
            },
            {
                "word": "whole",
                "meaning": "전부의 모든, 온전한"
            },
        ]
    },
    {
        detailId: "102-15",
        list: [
            {
                "word": "coin",
                "meaning": "동전"
            },
            {
                "word": "carry",
                "meaning": "나르다; 가지고 다니다"
            },
            {
                "word": "wind",
                "meaning": "바람"
            },
            {
                "word": "inside",
                "meaning": "~의 안쪽에; 내부의; 안쪽, 내부"
            },
            {
                "word": "tired",
                "meaning": "피곤한, 지친; 싫증난"
            },
            {
                "word": "thing",
                "meaning": "(사물을 가리키는) 것, 물건"
            },
            {
                "word": "send",
                "meaning": "보내다, 발송하다"
            },
            {
                "word": "gentleman",
                "meaning": "신사"
            },
            {
                "word": "bath",
                "meaning": "목욕, 욕조"
            },
            {
                "word": "always",
                "meaning": "항상, 언제나"
            }
        ]
    },
    {
        detailId: "201-01",
        list: [
            {
                "word": "calm",
                "meaning": "침착한, 차분한"
            },
            {
                "word": "rough",
                "meaning": "고르지 않은,거친"
            },
            {
                "word": "grab",
                "meaning": "붙잡다,움켜쥐다"
            },
            {
                "word": "contest",
                "meaning": "대회, 시합, 콘테스트"
            },
            {
                "word": "carry",
                "meaning": "나르다; 가지고 다니다"
            },
            {
                "word": "close",
                "meaning": "가까운; 거의~할 것 같은"
            },
            {
                "word": "reply",
                "meaning": "대답하다, 대응하다"
            },
            {
                "word": "control",
                "meaning": "관리하다, 통제하다"
            },
            {
                "word": "talented",
                "meaning": "재능이있는"
            },
            {
                "word": "ground",
                "meaning": "땅, 지면; 운동장"
            },
        ]
    },
    {
        detailId: "201-02",
        list: [
            {
                "word": "issue",
                "meaning": "문제, 이슈"
            },
            {
                "word": "soil",
                "meaning": "토양, 흙, 땅;"
            },
            {
                "word": "reason",
                "meaning": "이유, 원인; 이성;"
            },
            {
                "word": "riddle",
                "meaning": "수수께끼"
            },
            {
                "word": "beside",
                "meaning": "~옆에, ~와 나란히; ~에 비하면"
            },
            {
                "word": "lantern",
                "meaning": "랜턴, 손전등"
            },
            {
                "word": "hurrah",
                "meaning": "만세"
            },
            {
                "word": "cousin",
                "meaning": "사촌"
            },
            {
                "word": "butterfly",
                "meaning": "나비; (수영의)접영"
            },
            {
                "word": "migrate",
                "meaning": "(새, 물고기, 동물 등이 철 따라)이동하다, 이주하다"
            },
        ]
    },
    {
        detailId: "201-03",
        list: [
            {
                "word": "deep",
                "meaning": "깊은"
            },
            {
                "word": "stay",
                "meaning": "머무르다, 체류하다"
            },
            {
                "word": "mask",
                "meaning": "(보호용)마스크; 가면"
            },
            {
                "word": "dark",
                "meaning": "어두운, 캄캄한;"
            },
            {
                "word": "humor",
                "meaning": "유머"
            },
            {
                "word": "hit",
                "meaning": "때리다, 치다"
            },
            {
                "word": "congratulation",
                "meaning": "축하"
            },
            {
                "word": "crew",
                "meaning": "승무원"
            },
            {
                "word": "closet",
                "meaning": "벽장"
            },
            {
                "word": "will",
                "meaning": "~할 것이다"
            },
        ]
    },
    {
        detailId: "201-04",
        list: [
            {
                "word": "handle",
                "meaning": "다루다, 처리하다;"
            },
            {
                "word": "touch",
                "meaning": "만지다; 감동시키다"
            },
            {
                "word": "site",
                "meaning": "현장, 부지, 장소;"
            },
            {
                "word": "march",
                "meaning": "행진하다;"
            },
            {
                "word": "office",
                "meaning": "사무실; 회사"
            },
            {
                "word": "law",
                "meaning": "법, 법률"
            },
            {
                "word": "sour",
                "meaning": "신, 시큼한;"
            },
            {
                "word": "beg",
                "meaning": "구걸하다, 간청하다"
            },
            {
                "word": "trade",
                "meaning": "무역, 교역, 거래;"
            },
            {
                "word": "pardon",
                "meaning": "사면; 용서;"
            },
        ]
    },
    {
        detailId: "201-05",
        list: [
            {
                "word": "allow",
                "meaning": "허용하다, 허락하다; 인정하다"
            },
            {
                "word": "major",
                "meaning": "주요한; 전공의;"
            },
            {
                "word": "whisker",
                "meaning": "구레나룻; (고양이,쥐 등의)수염"
            },
            {
                "word": "southern",
                "meaning": "남쪽의, 남부의"
            },
            {
                "word": "bolt",
                "meaning": "볼트, 나사못; (문을 잠그는) 빗장"
            },
            {
                "word": "palace",
                "meaning": "궁전, 왕실"
            },
            {
                "word": "sleep",
                "meaning": "자다;"
            },
            {
                "word": "telephone",
                "meaning": "전화, 전화기;"
            },
            {
                "word": "burden",
                "meaning": "부담, 짐;"
            },
            {
                "word": "lean",
                "meaning": "기대다; 기울다"
            },
        ]
    },
    {
        detailId: "201-06",
        list: [
            {
                "word": "discuss",
                "meaning": "토론하다, 논의하다, 상의하다"
            },
            {
                "word": "hastily",
                "meaning": "서둘러서, 급히"
            },
            {
                "word": "experience",
                "meaning": "경험, 체험;"
            },
            {
                "word": "favorite",
                "meaning": "좋아하는;"
            },
            {
                "word": "lost",
                "meaning": "길을 잃은; 잃어버린, 분실된"
            },
            {
                "word": "theater",
                "meaning": "극장"
            },
            {
                "word": "tail",
                "meaning": "꼬리; 끝"
            },
            {
                "word": "research",
                "meaning": "연구, 조사;"
            },
            {
                "word": "spring",
                "meaning": "봄; 용수철;"
            },
            {
                "word": "miserable",
                "meaning": "비참한, 불쌍한"
            },
        ]
    },
    {
        detailId: "201-07",
        list: [
            {
                "word": "drive",
                "meaning": "운전하다"
            },
            {
                "word": "cost",
                "meaning": "비용, 가격;"
            },
            {
                "word": "break",
                "meaning": "휴식, 휴가;"
            },
            {
                "word": "throat",
                "meaning": "목(구멍), 인후, 숨통"
            },
            {
                "word": "gallery",
                "meaning": "미술관, 갤러리"
            },
            {
                "word": "wax",
                "meaning": "밀랍, 왁스;"
            },
            {
                "word": "doll",
                "meaning": "인형"
            },
            {
                "word": "custom",
                "meaning": "세관(s); 관습, 풍습"
            },
            {
                "word": "abuse",
                "meaning": "남용, 오용; 학대;"
            },
            {
                "word": "realize",
                "meaning": "깨닫다, 알다, 인식하다; 실현하다"
            },
        ]
    },
    {
        detailId: "201-08",
        list: [
            {
                "word": "bar",
                "meaning": "술집; 막대기;"
            },
            {
                "word": "opinion",
                "meaning": "의견"
            },
            {
                "word": "idea",
                "meaning": "생각, 아이디어"
            },
            {
                "word": "hide",
                "meaning": "숨기다, 감추다;"
            },
            {
                "word": "booth",
                "meaning": "부스, (칸막이를 한)작은 공간"
            },
            {
                "word": "mop",
                "meaning": "대걸레, 자루걸레;"
            },
            {
                "word": "nature",
                "meaning": "자연; 본성"
            },
            {
                "word": "surface",
                "meaning": "표면, 바닥, 외관;"
            },
            {
                "word": "lead",
                "meaning": "이끌다, 지휘하다;"
            },
            {
                "word": "fault",
                "meaning": "잘못; 결점"
            },
        ]
    },
    {
        detailId: "201-09",
        list: [
            {
                "word": "promise",
                "meaning": "약속하다;"
            },
            {
                "word": "angry",
                "meaning": "성난, 화난"
            },
            {
                "word": "while",
                "meaning": "~하는 동안; ~하는 반면에"
            },
            {
                "word": "lamb",
                "meaning": "새끼양;"
            },
            {
                "word": "steel",
                "meaning": "강철; 철강 산업"
            },
            {
                "word": "hobby",
                "meaning": "취미"
            },
            {
                "word": "familiar",
                "meaning": "익숙한, 친숙한"
            },
            {
                "word": "belong",
                "meaning": "속하다"
            },
            {
                "word": "independent",
                "meaning": "독립한"
            },
            {
                "word": "horn",
                "meaning": "뿔; (자동차의) 경적"
            },
        ]
    },
    {
        detailId: "201-10",
        list: [
            {
                "word": "museum",
                "meaning": "박물관; 미술관"
            },
            {
                "word": "schedule",
                "meaning": "일정, 예정, 계획; 시간표;"
            },
            {
                "word": "purse",
                "meaning": "지갑, (특히 어깨끈이 없는) 핸드백"
            },
            {
                "word": "animation",
                "meaning": "애니메이션, 만화 영화; 생기, 활기"
            },
            {
                "word": "text",
                "meaning": "글, 문자; 본문; 교재;"
            },
            {
                "word": "end",
                "meaning": "끝, 마지막;"
            },
            {
                "word": "snake",
                "meaning": "뱀"
            },
            {
                "word": "shore",
                "meaning": "해안"
            },
            {
                "word": "sell",
                "meaning": "팔다"
            },
            {
                "word": "dry",
                "meaning": "말리다;"
            },
        ]
    },
    {
        detailId: "201-11",
        list: [
            {
                "word": "sunshine",
                "meaning": "햇빛, 햇살"
            },
            {
                "word": "stripe",
                "meaning": "줄무늬"
            },
            {
                "word": "cemetery",
                "meaning": "묘지"
            },
            {
                "word": "smooth",
                "meaning": "매끄러운, 평탄한, (수면이) 잔잔한;"
            },
            {
                "word": "crop",
                "meaning": "농작물, 수확물; 수확량"
            },
            {
                "word": "lie",
                "meaning": "누워있다; 놓여있다; 거짓말하다;"
            },
            {
                "word": "help",
                "meaning": "돕다;"
            },
            {
                "word": "sight",
                "meaning": "시력; 보기, 시야, 광경"
            },
            {
                "word": "blow",
                "meaning": "불다;"
            },
            {
                "word": "wolf",
                "meaning": "늑대"
            },
        ]
    },
    {
        detailId: "201-12",
        list: [
            {
                "word": "eraser",
                "meaning": "지우개"
            },
            {
                "word": "neighbor",
                "meaning": "이웃, 주변 사람"
            },
            {
                "word": "marry",
                "meaning": "결혼하다"
            },
            {
                "word": "belt",
                "meaning": "벨트, 허리띠"
            },
            {
                "word": "virtual",
                "meaning": "사실상의, 실제의; (컴퓨터를 이용한) 가상의"
            },
            {
                "word": "short",
                "meaning": "짧은, 키가 작은; 부족한"
            },
            {
                "word": "spread",
                "meaning": "펼치다, 확산시키다;"
            },
            {
                "word": "hesitate",
                "meaning": "망설이다, 주저하다"
            },
            {
                "word": "change",
                "meaning": "바꾸다, 달라지다, 수정하다;"
            },
            {
                "word": "clerk",
                "meaning": "점원, 사무원"
            },
        ]
    },
    {
        detailId: "201-13",
        list: [
            {
                "word": "elderly",
                "meaning": "나이 많은, 나이가 지긋한;"
            },
            {
                "word": "item",
                "meaning": "항목, 품목, 아이템"
            },
            {
                "word": "skillful",
                "meaning": "숙련된, 솜씨 좋은, 능숙한"
            },
            {
                "word": "participate",
                "meaning": "참가하다, 참여하다"
            },
            {
                "word": "obey",
                "meaning": "복종하다, 순종하다; 준수하다, 따르다"
            },
            {
                "word": "even",
                "meaning": "~도, ~조차, ~까지도;"
            },
            {
                "word": "miss",
                "meaning": "놓치다; 그리워하다;"
            },
            {
                "word": "suburb",
                "meaning": "교외, (도시의) 근교"
            },
            {
                "word": "consider",
                "meaning": "교려하다, 숙고하다, 간주하다, 생각한다, 여기다"
            },
            {
                "word": "corn",
                "meaning": "곡물, 곡식, 옥수수"
            },
        ]
    },
    {
        detailId: "201-14",
        list: [
            {
                "word": "iron",
                "meaning": "철, 쇠; 다리미;"
            },
            {
                "word": "surround",
                "meaning": "둘러싸다, 에워싸다; 포위하다"
            },
            {
                "word": "situation",
                "meaning": "상황, 처지, 상태; 위치"
            },
            {
                "word": "responsible",
                "meaning": "책임이 있는, 담당의"
            },
            {
                "word": "mammal",
                "meaning": "포유동물"
            },
            {
                "word": "living",
                "meaning": "살아 있는; 생활의;"
            },
            {
                "word": "cloudy",
                "meaning": "구름이 낀, 흐린"
            },
            {
                "word": "bring",
                "meaning": "가져오다, 데려오다"
            },
            {
                "word": "goddess",
                "meaning": "여신"
            },
            {
                "word": "everywhere",
                "meaning": "어디나, 모든 곳"
            },
        ]
    },
    {
        detailId: "201-15",
        list: [
            {
                "word": "fortune",
                "meaning": "재산; 행운, 운"
            },
            {
                "word": "stage",
                "meaning": "단계, 시기; 무대;"
            },
            {
                "word": "former",
                "meaning": "(순서) 전의, 전임의, 예전의"
            },
            {
                "word": "classic",
                "meaning": "전형적인, 고전의;"
            },
            {
                "word": "wave",
                "meaning": "파도, 물결;"
            },
            {
                "word": "problem",
                "meaning": "문제"
            },
            {
                "word": "fear",
                "meaning": "두려움, 공포;"
            },
            {
                "word": "present",
                "meaning": "현재의;"
            },
            {
                "word": "dumb",
                "meaning": "멍청한; 말을 못 하는"
            },
            {
                "word": "chemical",
                "meaning": "화학의, 화학적인"
            }
        ]
    },


    {
        detailId: "202-01",
        list: [
            {
                "word": "monument",
                "meaning": "기념물, 기념비"
            },
            {
                "word": "lock",
                "meaning": "잠그다;"
            },
            {
                "word": "scold",
                "meaning": "꾸짖다, 야단치다"
            },
            {
                "word": "unbelievable",
                "meaning": "믿기 어려울 정도인, 믿기 힘든"
            },
            {
                "word": "servant",
                "meaning": "하인, 종, 고용인"
            },
            {
                "word": "college",
                "meaning": "대학"
            },
            {
                "word": "hunter",
                "meaning": "사냥꾼"
            },
            {
                "word": "weigh",
                "meaning": "무게가 ~이다, 무게를 달다"
            },
            {
                "word": "safely",
                "meaning": "무사히, 안전하게"
            },
            {
                "word": "completely",
                "meaning": "완전히, 전적으로"
            },
        ]
    },
    {
        detailId: "202-02",
        list: [
            {
                "word": "idiom",
                "meaning": "관용구, 숙어"
            },
            {
                "word": "bet",
                "meaning": "내기를 하다;"
            },
            {
                "word": "hike",
                "meaning": "하이킹, 도보 여행;"
            },
            {
                "word": "anybody",
                "meaning": "누구든, 아무도; 누군가"
            },
            {
                "word": "weak",
                "meaning": "약한, 힘이 없는"
            },
            {
                "word": "discover",
                "meaning": "발견하다, 밝히다"
            },
            {
                "word": "recycle",
                "meaning": "재활용하다, 재생하다"
            },
            {
                "word": "last",
                "meaning": "지난, 가장 최근의; 마지막의;"
            },
            {
                "word": "replace",
                "meaning": "대체하다, 교체하다, 바꾸다; 제자리에 놓다"
            },
            {
                "word": "blood",
                "meaning": "피, 혈액"
            },
        ]
    },
    {
        detailId: "202-03",
        list: [
            {
                "word": "justice",
                "meaning": "정의; 공정; 사법; 재판"
            },
            {
                "word": "choose",
                "meaning": "선택하다, 고르다"
            },
            {
                "word": "level",
                "meaning": "수준, 레벨"
            },
            {
                "word": "toothache",
                "meaning": "치통"
            },
            {
                "word": "hiking",
                "meaning": "하이킹, 도보 여행"
            },
            {
                "word": "muscle",
                "meaning": "근육"
            },
            {
                "word": "positive",
                "meaning": "긍정적인"
            },
            {
                "word": "over",
                "meaning": "~이상, ~을 넘어; ~위에"
            },
            {
                "word": "grade",
                "meaning": "등급; 학년; 성적;"
            },
            {
                "word": "recently",
                "meaning": "최근에"
            },
        ]
    },
    {
        detailId: "202-04",
        list: [
            {
                "word": "though",
                "meaning": "비록 ~일지라도"
            },
            {
                "word": "fight",
                "meaning": "싸우다;"
            },
            {
                "word": "blank",
                "meaning": "(글자가 없는) 빈, 공백의;"
            },
            {
                "word": "western",
                "meaning": "서쪽의, 서부의"
            },
            {
                "word": "chest",
                "meaning": "가슴, 흉부; (나무로 만든) 상자"
            },
            {
                "word": "treasure",
                "meaning": "보물;"
            },
            {
                "word": "bold",
                "meaning": "대담한, 용감한; 굵은;"
            },
            {
                "word": "traffic",
                "meaning": "교통"
            },
            {
                "word": "slip",
                "meaning": "미끄러지다; 떨어지다;"
            },
            {
                "word": "basic",
                "meaning": "기본적인, 기초적인"
            },
        ]
    },
    {
        detailId: "202-05",
        list: [
            {
                "word": "scared",
                "meaning": "무서운, 겁먹은, 두려워하는"
            },
            {
                "word": "ceiling",
                "meaning": "천장; 최고 한도, 상한"
            },
            {
                "word": "doubt",
                "meaning": "의심하다;"
            },
            {
                "word": "low",
                "meaning": "낮은"
            },
            {
                "word": "basement",
                "meaning": "(건물의) 지하실, 지하층"
            },
            {
                "word": "steal",
                "meaning": "훔치다, 도둑질하다;"
            },
            {
                "word": "skin",
                "meaning": "(사람의) 피부; (동물의) 가죽"
            },
            {
                "word": "attend",
                "meaning": "참석하다, 출석하다; 수반하다"
            },
            {
                "word": "fever",
                "meaning": "열, 고열; 열광, 흥분;"
            },
            {
                "word": "wisdom",
                "meaning": "지혜, 슬기, 현명함"
            },
        ]
    },
    {
        detailId: "202-06",
        list: [
            {
                "word": "marble",
                "meaning": "대리석; 구슬"
            },
            {
                "word": "puzzle",
                "meaning": "퍼즐, 수수께끼; 당황"
            },
            {
                "word": "leak",
                "meaning": "(액체,가스 등이)새다;"
            },
            {
                "word": "hurry",
                "meaning": "서두르다;"
            },
            {
                "word": "earthquake",
                "meaning": "지진"
            },
            {
                "word": "pollution",
                "meaning": "오염; 공해"
            },
            {
                "word": "exercise",
                "meaning": "운동"
            },
            {
                "word": "gain",
                "meaning": "얻다;"
            },
            {
                "word": "side",
                "meaning": "(물체의 앞 뒤.위 아래에 대하여)쪽, 측, 편, 옆"
            },
            {
                "word": "glory",
                "meaning": "영광"
            },
        ]
    },
    {
        detailId: "202-07",
        list: [
            {
                "word": "fortunately",
                "meaning": "다행히; 운좋게"
            },
            {
                "word": "neat",
                "meaning": "깔끔한, 단정한"
            },
            {
                "word": "officer",
                "meaning": "장교, 경찰관, 공무원"
            },
            {
                "word": "pace",
                "meaning": "속도, 페이스, 보폭;"
            },
            {
                "word": "boast",
                "meaning": "자랑하다, 뽐내다;"
            },
            {
                "word": "primary",
                "meaning": "주된, 주요한; 첫째의; 최초의; 초급의"
            },
            {
                "word": "seed",
                "meaning": "씨, 씨앗, 종자;"
            },
            {
                "word": "toll",
                "meaning": "통행료, 사용료; 사상자수"
            },
            {
                "word": "escape",
                "meaning": "탈출하다, 달아나다;"
            },
            {
                "word": "suffer",
                "meaning": "시달리다, 고통받다; 악화되다"
            },
        ]
    },
    {
        detailId: "202-08",
        list: [
            {
                "word": "sunlight",
                "meaning": "햇빛, 햇살, 일광"
            },
            {
                "word": "brand",
                "meaning": "상표, 브랜드"
            },
            {
                "word": "produce",
                "meaning": "생산하다;"
            },
            {
                "word": "practice",
                "meaning": "실행, 연습; 관습;"
            },
            {
                "word": "gradually",
                "meaning": "점차적으로, 서서히"
            },
            {
                "word": "meeting",
                "meaning": "회의, 만남"
            },
            {
                "word": "foolish",
                "meaning": "어리석은, 바보 같은"
            },
            {
                "word": "backward",
                "meaning": "뒤쪽으로, 뒤로 향하여"
            },
            {
                "word": "squirrel",
                "meaning": "다람쥐"
            },
            {
                "word": "schoolwork",
                "meaning": "학업, 학교 공부"
            },
        ]
    },
    {
        detailId: "202-09",
        list: [
            {
                "word": "hero",
                "meaning": "영웅"
            },
            {
                "word": "strip",
                "meaning": "(옷을) 벗다;"
            },
            {
                "word": "patient",
                "meaning": "환자;"
            },
            {
                "word": "devil",
                "meaning": "악마"
            },
            {
                "word": "solution",
                "meaning": "해법, 해결책; 용액"
            },
            {
                "word": "host",
                "meaning": "개최하다, 주최하다;"
            },
            {
                "word": "sale",
                "meaning": "판매; (~s) 매출(액); 세일, 재고 정리 판매"
            },
            {
                "word": "soap",
                "meaning": "비누;"
            },
            {
                "word": "swing",
                "meaning": "흔들리다, 흔들다;"
            },
            {
                "word": "shell",
                "meaning": "껍질; 포탄, 탄피"
            },
        ]
    },
    {
        detailId: "202-10",
        list: [
            {
                "word": "loose",
                "meaning": "헐렁한, 느슨한;"
            },
            {
                "word": "greeting",
                "meaning": "인사, 인사말"
            },
            {
                "word": "similar",
                "meaning": "비슷한, 유사한, 닮은"
            },
            {
                "word": "press",
                "meaning": "누르다;"
            },
            {
                "word": "broadcast",
                "meaning": "방송하다"
            },
            {
                "word": "rise",
                "meaning": "증가, 상승;"
            },
            {
                "word": "complete",
                "meaning": "완료하다, 완성하다;"
            },
            {
                "word": "shade",
                "meaning": "그늘; (그림의) 음영, 색조; 명암"
            },
            {
                "word": "elder",
                "meaning": "나이가 더 많은;"
            },
            {
                "word": "yell",
                "meaning": "소리 지르다, 외치다"
            },
        ]
    },
    {
        detailId: "202-11",
        list: [
            {
                "word": "surprise",
                "meaning": "놀라운 일;"
            },
            {
                "word": "myself",
                "meaning": "나 자신을, 나 자신에게"
            },
            {
                "word": "tribe",
                "meaning": "종족, 부족; 집단"
            },
            {
                "word": "triumph",
                "meaning": "승리, 업적;"
            },
            {
                "word": "knit",
                "meaning": "(옷 등을) 뜨다; 결합시키다"
            },
            {
                "word": "disadvantage",
                "meaning": "불리한 점(조건), 약점"
            },
            {
                "word": "thinker",
                "meaning": "사상가; (~하게) 생각하는 사람"
            },
            {
                "word": "charming",
                "meaning": "매력적인, 멋진"
            },
            {
                "word": "Puritan",
                "meaning": "(종교) 청교도"
            },
            {
                "word": "pleasant",
                "meaning": "즐거운, 유쾌한"
            },
        ]
    },
    {
        detailId: "202-12",
        list: [
            {
                "word": "bike",
                "meaning": "자전거, 오토바이;"
            },
            {
                "word": "plate",
                "meaning": "접시; 판;"
            },
            {
                "word": "sum",
                "meaning": "합계, 총액;"
            },
            {
                "word": "spill",
                "meaning": "쏟아지다, 흘리다;"
            },
            {
                "word": "right",
                "meaning": "권리"
            },
            {
                "word": "banner",
                "meaning": "플랜카드, 현수막"
            },
            {
                "word": "whole",
                "meaning": "전부의, 모든, 온전한;"
            },
            {
                "word": "war",
                "meaning": "전쟁"
            },
            {
                "word": "sugar",
                "meaning": "설탕;"
            },
            {
                "word": "coeducation",
                "meaning": "남녀 공학"
            },
        ]
    },
    {
        detailId: "202-13",
        list: [
            {
                "word": "sand",
                "meaning": "모래; 모래 벌판; 사막"
            },
            {
                "word": "oath",
                "meaning": "맹세, 서약, 선서"
            },
            {
                "word": "remove",
                "meaning": "제거하다, 없애다; 치우다; 해고하다"
            },
            {
                "word": "democracy",
                "meaning": "민주주의"
            },
            {
                "word": "fat",
                "meaning": "살찐, 뚱뚱한;"
            },
            {
                "word": "insert",
                "meaning": "넣다, 삽입하다"
            },
            {
                "word": "vet",
                "meaning": "수의사"
            },
            {
                "word": "pipe",
                "meaning": "관, 파이프"
            },
            {
                "word": "fashion",
                "meaning": "패션, 유행"
            },
            {
                "word": "interesting",
                "meaning": "흥미로운"
            },
        ]
    },
    {
        detailId: "202-14",
        list: [
            {
                "word": "airport",
                "meaning": "공항, 비행장"
            },
            {
                "word": "humorous",
                "meaning": "유머러스한, 우스운"
            },
            {
                "word": "project",
                "meaning": "프로젝트, 계획, 기획;"
            },
            {
                "word": "somebody",
                "meaning": "어떤 사람, 누군가"
            },
            {
                "word": "add",
                "meaning": "더하다, 추가하다"
            },
            {
                "word": "merry",
                "meaning": "즐거운, 명랑한, 유쾌한"
            },
            {
                "word": "pollute",
                "meaning": "오염시키다"
            },
            {
                "word": "kite",
                "meaning": "연; (조류) 솔개"
            },
            {
                "word": "wing",
                "meaning": "날개; 부속 건물"
            },
            {
                "word": "sick",
                "meaning": "아픈, 병든"
            },
            {
                "word": "indoor",
                "meaning": "실내의"
            }
        ]
    },

    {
        detailId: "301-01",
        list: [
            {
                "word": "review",
                "meaning": "논평; 재검토;"
            },
            {
                "word": "involve",
                "meaning": "포함하다, 수반하다; 연루시키다"
            },
            {
                "word": "responsible",
                "meaning": "책임이 있는, 담당의"
            },
            {
                "word": "anthropology",
                "meaning": "인류학"
            },
            {
                "word": "exterior",
                "meaning": "외부의, 외면의;"
            },
            {
                "word": "barbarian",
                "meaning": "야만인; 이방인; 미개인"
            },
            {
                "word": "gender",
                "meaning": "성별"
            },
            {
                "word": "ambiguous",
                "meaning": "애매한"
            },
            {
                "word": "belong",
                "meaning": "속하다"
            },
            {
                "word": "season",
                "meaning": "시즌, 계절, 시기;"
            },
        ]
    },
    {
        detailId: "301-02",
        list: [
            {
                "word": "variable",
                "meaning": "변동이 심한; 가변적인;"
            },
            {
                "word": "trend",
                "meaning": "동향, 경향, 추세"
            },
            {
                "word": "uphold",
                "meaning": "유지시키다; 확인하다; 지지하다, 인정하다"
            },
            {
                "word": "mine",
                "meaning": "내 것;"
            },
            {
                "word": "disinterested",
                "meaning": "사심이 없는, 객관적인, 공평한; 관심이 없는"
            },
            {
                "word": "relax",
                "meaning": "휴식을 취하다;긴장을 풀다"
            },
            {
                "word": "disgust",
                "meaning": "혐오감, 역겨움;"
            },
            {
                "word": "companion",
                "meaning": "동반자, 동료"
            },
            {
                "word": "brisk",
                "meaning": "호황의, 활발한"
            },
            {
                "word": "invention",
                "meaning": "발명, 발명품"
            },
        ]
    },
    {
        detailId: "301-03",
        list: [
            {
                "word": "heed",
                "meaning": "주의를 기울이다;"
            },
            {
                "word": "observance",
                "meaning": "(규칙 등의) 준수; 축하"
            },
            {
                "word": "run",
                "meaning": "달리다; 운영하다; 작동하다; 개최하다;"
            },
            {
                "word": "gain",
                "meaning": "얻다;"
            },
            {
                "word": "breath",
                "meaning": "숨, 호흡"
            },
            {
                "word": "various",
                "meaning": "여러 가지의, 가지각색의, 다양한"
            },
            {
                "word": "lump",
                "meaning": "덩어리; 혹;"
            },
            {
                "word": "sacrifice",
                "meaning": "희생; 희생물;"
            },
            {
                "word": "declare",
                "meaning": "선언하다; 말하다; (세관에 과세 물품을) 신고하다"
            },
            {
                "word": "notify",
                "meaning": "알리다, 통보하다"
            },
        ]
    },
    {
        detailId: "301-04",
        list: [
            {
                "word": "deserve",
                "meaning": "~할 자격이 있다, ~을 받을 만하다"
            },
            {
                "word": "investigate",
                "meaning": "조사하다"
            },
            {
                "word": "ordinary",
                "meaning": "보통의, 일상적인, 평범한"
            },
            {
                "word": "compel",
                "meaning": "강요하다, ~하게 하다"
            },
            {
                "word": "offspring",
                "meaning": "자식, 새끼; 자손"
            },
            {
                "word": "uninterested",
                "meaning": "흥미 없는, 관심 없는, 무관심한"
            },
            {
                "word": "absurd",
                "meaning": "터무니없는, 말도 안되는;"
            },
            {
                "word": "scratch",
                "meaning": "긁다, 할퀴다;"
            },
            {
                "word": "precede",
                "meaning": "앞서다, 선행하다; 우선하다"
            },
            {
                "word": "liable",
                "meaning": "책임져야 할; ~하기 쉬운; ~할 것 같은"
            },
        ]
    },
    {
        detailId: "301-05",
        list: [
            {
                "word": "carrier",
                "meaning": "항공사, 운송회사"
            },
            {
                "word": "fiber",
                "meaning": "섬유, 섬유질"
            },
            {
                "word": "countenance",
                "meaning": "(얼굴의) 표정, 안색;"
            },
            {
                "word": "planet",
                "meaning": "행성, 유성"
            },
            {
                "word": "tense",
                "meaning": "긴장한; 긴박한; (전선 등이) 팽팽한;"
            },
            {
                "word": "equal",
                "meaning": "같은, 동일한, 동등한"
            },
            {
                "word": "dispel",
                "meaning": "쫓아버리다, 떨쳐버리다"
            },
            {
                "word": "casual",
                "meaning": "캐주얼의; 우연의; 임시의;"
            },
            {
                "word": "audience",
                "meaning": "청중, 관중, 시청자"
            },
            {
                "word": "cost",
                "meaning": "비용, 가격;"
            },
        ]
    },
    {
        detailId: "301-06",
        list: [{
            "word": "devastate",
            "meaning": "완전히 파괴하다, 황폐시키다"
        },
            {
                "word": "anguish",
                "meaning": "고통, 비통"
            },
            {
                "word": "profess",
                "meaning": "공언하다; 고백하다; 자칭하다"
            },
            {
                "word": "marvel",
                "meaning": "놀라다, 경이로워하다;"
            },
            {
                "word": "staple",
                "meaning": "주된, 주요한;"
            },
            {
                "word": "repel",
                "meaning": "쫓아버리다, 격퇴하다, 물리치다"
            },
            {
                "word": "scramble",
                "meaning": "스크램블;"
            },
            {
                "word": "ignorance",
                "meaning": "무지, 무식"
            },
            {
                "word": "scheme",
                "meaning": "계획, 설계; 음모; 조직"
            },
            {
                "word": "fond",
                "meaning": "좋아하는, 애정을 느끼는"
            },]
    },
    {
        detailId: "301-07",
        list: [{
            "word": "parental",
            "meaning": "부모의, 부모다운"
        },
            {
                "word": "bond",
                "meaning": "채권; 본드; 유대;"
            },
            {
                "word": "accuse",
                "meaning": "비난하다; 고소하다"
            },
            {
                "word": "portrait",
                "meaning": "초상화"
            },
            {
                "word": "atomic",
                "meaning": "원자(력)의; 핵무기의"
            },
            {
                "word": "propaganda",
                "meaning": "(주의, 주장 등의) 선전"
            },
            {
                "word": "puzzle",
                "meaning": "퍼즐, 수수께끼; 당황"
            },
            {
                "word": "paddle",
                "meaning": "노를 젓다;"
            },
            {
                "word": "rare",
                "meaning": "드문, 진귀한, 희귀한"
            },
            {
                "word": "commission",
                "meaning": "위원회; 수당, 수수료"
            },]
    },
    {
        detailId: "301-08",
        list: [
            {
                "word": "assist",
                "meaning": "돕다, 거들다;"
            },
            {
                "word": "longevity",
                "meaning": "장수, 장명; 수명"
            },
            {
                "word": "debris",
                "meaning": "잔해, 파편"
            },
            {
                "word": "thermometer",
                "meaning": "온도계; 체온계"
            },
            {
                "word": "choir",
                "meaning": "합창단, 성가대;"
            },
            {
                "word": "faithful",
                "meaning": "신뢰할 만한, 신의 있는, 충실한"
            },
            {
                "word": "digestion",
                "meaning": "소화"
            },
            {
                "word": "riddle",
                "meaning": "수수께끼"
            },
            {
                "word": "establish",
                "meaning": "설립하다, 수립하다; 제정하다"
            },
            {
                "word": "launch",
                "meaning": "출시하다, 시작하다; 발사하다;"
            },
        ]
    },
    {
        detailId: "301-09",
        list: [
            {
                "word": "gather",
                "meaning": "모으다, 모이다;"
            },
            {
                "word": "inevitable",
                "meaning": "피할 수 없는, 불가피한"
            },
            {
                "word": "modest",
                "meaning": "겸손한, 얌전한; 적당한"
            },
            {
                "word": "foggy",
                "meaning": "안개 낀"
            },
            {
                "word": "spoil",
                "meaning": "망치다, 버리다, 손상하다;"
            },
            {
                "word": "sensible",
                "meaning": "분별 있는, 현명한, 합법적인"
            },
            {
                "word": "spur",
                "meaning": "박차, 자극(제);"
            },
            {
                "word": "mammal",
                "meaning": "포유동물"
            },
            {
                "word": "deplete",
                "meaning": "고갈시키다, 다 써버리다"
            },
            {
                "word": "consider",
                "meaning": "고려하다, 숙고하다, 간주하다, 생각한다, 여기다"
            },
        ]
    },
    {
        detailId: "301-10",
        list: [
            {
                "word": "emphasize",
                "meaning": "강조하다"
            },
            {
                "word": "emigrate",
                "meaning": "이민하다, 이주하다"
            },
            {
                "word": "conduct",
                "meaning": "실행하다, 수행하다; 지휘하다;"
            },
            {
                "word": "contest",
                "meaning": "대회, 시합, 콘테스트"
            },
            {
                "word": "elastic",
                "meaning": "탄력 있는, 신축성이 있는, 고무로 된;"
            },
            {
                "word": "sword",
                "meaning": "칼, 검"
            },
            {
                "word": "haste",
                "meaning": "서두름, 급함;"
            },
            {
                "word": "castle",
                "meaning": "성"
            },
            {
                "word": "deposit",
                "meaning": "예금하다, 맡기다;"
            },
            {
                "word": "install",
                "meaning": "설치하다; 임명하다"
            },
        ]
    },
    {
        detailId: "301-11",
        list: [
            {
                "word": "sinister",
                "meaning": "불길한; 사악한, 해로운"
            },
            {
                "word": "dessert",
                "meaning": "디저트, 후식"
            },
            {
                "word": "implementation",
                "meaning": "실행, 이행; 성취"
            },
            {
                "word": "drown",
                "meaning": "익사하다, 물에 빠져 죽다"
            },
            {
                "word": "space",
                "meaning": "공간, 우주"
            },
            {
                "word": "factor",
                "meaning": "요인, 요소, 원인;"
            },
            {
                "word": "intrude",
                "meaning": "침범하다, 방해하다"
            },
            {
                "word": "polite",
                "meaning": "예의 바른, 공손한"
            },
            {
                "word": "leak",
                "meaning": "(액체,가스 등이)새다;"
            },
            {
                "word": "general",
                "meaning": "일반적인;"
            },
        ]
    },
    {
        detailId: "301-12",
        list: [{
            "word": "gravitation",
            "meaning": "중력, 인력"
        },
            {
                "word": "organism",
                "meaning": "생물(체), 유기체"
            },
            {
                "word": "prose",
                "meaning": "산문(체)"
            },
            {
                "word": "rush",
                "meaning": "서두르다, 돌진하다, 급히 움직이다;"
            },
            {
                "word": "raw",
                "meaning": "날것의, 익히지 않은"
            },
            {
                "word": "utmost",
                "meaning": "최대한의, 최고의, 극도의;"
            },
            {
                "word": "haunt",
                "meaning": "괴롭히다; 따라다니다, 나타나다"
            },
            {
                "word": "capable",
                "meaning": "능력있는, 할 수 있는; 유능한"
            },
            {
                "word": "alleviate",
                "meaning": "완화하다, 경감하다"
            },
            {
                "word": "sly",
                "meaning": "교활한, 음흉한"
            },]
    },
    {
        detailId: "301-13",
        list: [{
            "word": "malice",
            "meaning": "악의, 원한"
        },
            {
                "word": "plunge",
                "meaning": "(앞,아래로) 거꾸러지다; 던져넣다; 빠지게 하다; 급락하다"
            },
            {
                "word": "pessimistic",
                "meaning": "비관적인"
            },
            {
                "word": "leisure",
                "meaning": "여가, 레저"
            },
            {
                "word": "execute",
                "meaning": "실행하다, 집행하다"
            },
            {
                "word": "reprove",
                "meaning": "꾸짖다, 비난하다"
            },
            {
                "word": "axis",
                "meaning": "(수학) 축, 중심선"
            },
            {
                "word": "broadcast",
                "meaning": "방송하다"
            },
            {
                "word": "motivate",
                "meaning": "동기를 부여하다, 자극하다"
            },
            {
                "word": "diminish",
                "meaning": "감소하다, 줄어들다, 약화되다"
            },]
    },
    {
        detailId: "301-14",
        list: [{
            "word": "blast",
            "meaning": "폭발; 강한바람;"
        },
            {
                "word": "inherit",
                "meaning": "상속받다, 물려받다"
            },
            {
                "word": "side",
                "meaning": "(물체의 앞 뒤.위 아래에 대하여) 쪽, 측, 편, 옆"
            },
            {
                "word": "wonder",
                "meaning": "궁금하다, 놀라다;"
            },
            {
                "word": "confine",
                "meaning": "국한하다, 제한하다, 가두다"
            },
            {
                "word": "fluent",
                "meaning": "유창한"
            },
            {
                "word": "passage",
                "meaning": "통행; 통로; (문장 등의) 구절"
            },
            {
                "word": "ecology",
                "meaning": "생태; 생태학"
            },
            {
                "word": "invest",
                "meaning": "투자하다"
            },
            {
                "word": "prospect",
                "meaning": "전망, 예상, 가능성"
            },]
    },
    {
        detailId: "301-15",
        list: [{
            "word": "advance",
            "meaning": "진보, 전진;"
        },
            {
                "word": "intelligence",
                "meaning": "지능, 지성"
            },
            {
                "word": "proposition",
                "meaning": "제안; 주장"
            },
            {
                "word": "narrate",
                "meaning": "내레이션을 하다; 이야기를 하다, 설명하다"
            },
            {
                "word": "tension",
                "meaning": "긴장(상태), 갈등; 팽팽함"
            },
            {
                "word": "petty",
                "meaning": "작은, 사소한"
            },
            {
                "word": "bar",
                "meaning": "술집; 막대기;"
            },
            {
                "word": "maintain",
                "meaning": "유지하다, 지속하다; 주장하다"
            },
            {
                "word": "aware",
                "meaning": "알고 있는, 인식하고 있는"
            },
            {
                "word": "deliberate",
                "meaning": "고의적인, 의도적인;"
            },]
    },


    {
        detailId: "302-01",
        list: [{
            "word": "resemble",
            "meaning": "닮다, 유사하다"
        },
            {
                "word": "confidence",
                "meaning": "자신감, 신뢰"
            },
            {
                "word": "exemplary",
                "meaning": "모범적인, 본보기가 되는"
            },
            {
                "word": "paradise",
                "meaning": "천국, 낙원, 파라다이스"
            },
            {
                "word": "mean",
                "meaning": "의미하다, 뜻하다;"
            },
            {
                "word": "innate",
                "meaning": "타고난, 선천적인"
            },
            {
                "word": "hypothesize",
                "meaning": "가설을 세우다, 가정하다"
            },
            {
                "word": "liberal",
                "meaning": "자유주의의, 진보적인, 개방적인"
            },
            {
                "word": "figure",
                "meaning": "수치, 액수; 인물;"
            },
            {
                "word": "material",
                "meaning": "재료, 원료; 직물;"
            },]
    },
    {
        detailId: "302-02",
        list: [{
            "word": "sprain",
            "meaning": "(팔목, 발목을) 삐다, 접지르다;"
        },
            {
                "word": "sophomore",
                "meaning": "(고교,대학의) 2학년생"
            },
            {
                "word": "adventure",
                "meaning": "모험(심), 탐험"
            },
            {
                "word": "terrible",
                "meaning": "끔직한, 소름끼치는"
            },
            {
                "word": "cheat",
                "meaning": "속이다, 사기 치다;"
            },
            {
                "word": "flat",
                "meaning": "평평한; 납작한; 펑크난;"
            },
            {
                "word": "intimate",
                "meaning": "친밀한"
            },
            {
                "word": "settle",
                "meaning": "해결하다, 처리하다; 결정하다;"
            },
            {
                "word": "adversity",
                "meaning": "역경, 불운; 재난"
            },
            {
                "word": "handle",
                "meaning": "다루다, 처리하다;"
            },]
    },
    {
        detailId: "302-03",
        list: [{
            "word": "sufficient",
            "meaning": "충분한"
        },
            {
                "word": "prestige",
                "meaning": "위신, 명성;"
            },
            {
                "word": "prudent",
                "meaning": "신중한"
            },
            {
                "word": "annihilate",
                "meaning": "전멸시키다, 완패시키다"
            },
            {
                "word": "mighty",
                "meaning": "강력한, 힘센"
            },
            {
                "word": "filter",
                "meaning": "필터;"
            },
            {
                "word": "sturdy",
                "meaning": "튼튼한, 견고한"
            },
            {
                "word": "mar",
                "meaning": "손상시키다, 망쳐놓다, 훼손하다"
            },
            {
                "word": "earnestly",
                "meaning": "진지하게, 진정으로"
            },
            {
                "word": "shallow",
                "meaning": "얕은, 얄팍한, 천박한"
            },]
    },
    {
        detailId: "302-04",
        list: [{
            "word": "body",
            "meaning": "몸, 신체"
        },
            {
                "word": "journey",
                "meaning": "여행, 여정;"
            },
            {
                "word": "swear",
                "meaning": "맹세하다, 선서하다; 욕을하다"
            },
            {
                "word": "inexcusable",
                "meaning": "용납(용서)할 수 없는"
            },
            {
                "word": "headache",
                "meaning": "두통"
            },
            {
                "word": "touch",
                "meaning": "만지다; 감동시키다;"
            },
            {
                "word": "suspect",
                "meaning": "의심하다, 혐의를 두다;"
            },
            {
                "word": "deny",
                "meaning": "부인하다, 부정하다; 거부하다"
            },
            {
                "word": "refrain",
                "meaning": "자제하다, 삼가다"
            },
            {
                "word": "astonish",
                "meaning": "놀라게 하다"
            },]
    },
    {
        detailId: "302-05",
        list: [{
            "word": "transfer",
            "meaning": "옮기다, 이동하다, 전근가다;"
        },
            {
                "word": "thrust",
                "meaning": "(거칠게)밀다, 찌르다;"
            },
            {
                "word": "mustache",
                "meaning": "콧수염"
            },
            {
                "word": "notion",
                "meaning": "개념, 생각"
            },
            {
                "word": "medicine",
                "meaning": "약; 의학; 의사직"
            },
            {
                "word": "neutral",
                "meaning": "중립의;"
            },
            {
                "word": "envelope",
                "meaning": "봉투"
            },
            {
                "word": "confer",
                "meaning": "수여하다, 상담하다"
            },
            {
                "word": "conceit",
                "meaning": "자만심, 자부심"
            },
            {
                "word": "invalid",
                "meaning": "무효한, 타당하지 않은"
            },]
    },
    {
        detailId: "302-06",
        list: [{
            "word": "honorary",
            "meaning": "명예의"
        },
            {
                "word": "punctual",
                "meaning": "시간을 잘 지키는"
            },
            {
                "word": "elegy",
                "meaning": "애가, 비가, 슬픈노래"
            },
            {
                "word": "astronaut",
                "meaning": "우주 비행사"
            },
            {
                "word": "end",
                "meaning": "끝, 마지막;"
            },
            {
                "word": "sore",
                "meaning": "아픈, 쓰린, 따가운;"
            },
            {
                "word": "share",
                "meaning": "함께 쓰다, 공유하다, 나누다;"
            },
            {
                "word": "effect",
                "meaning": "효과, 영향; 결과;"
            },
            {
                "word": "release",
                "meaning": "발표하다, 공개하다, 출시하다; 풀어주다"
            },
            {
                "word": "pregnant",
                "meaning": "임신한"
            },]
    },
    {
        detailId: "302-07",
        list: [{
            "word": "somebody",
            "meaning": "어떤 사람, 누군가"
        },
            {
                "word": "theme",
                "meaning": "주제, 테마"
            },
            {
                "word": "maxim",
                "meaning": "격언, 금언"
            },
            {
                "word": "frontal",
                "meaning": "정면의, 앞면의"
            },
            {
                "word": "livelihood",
                "meaning": "생계, 살림"
            },
            {
                "word": "cliff",
                "meaning": "절벽"
            },
            {
                "word": "fit",
                "meaning": "알맞다, 적절하다;"
            },
            {
                "word": "habitual",
                "meaning": "습관적인"
            },
            {
                "word": "defy",
                "meaning": "반항하다; 거부하다, 설명하기 불가능하다; 무시하다"
            },
            {
                "word": "awe",
                "meaning": "두려움, 경외심"
            },]
    },
    {
        detailId: "302-08",
        list: [{
            "word": "turbulence",
            "meaning": "(물,공기의)난류, 난기류; 격동, 격변, 동요"
        },
            {
                "word": "glory",
                "meaning": "영광"
            },
            {
                "word": "want",
                "meaning": "원하다, ~하고 싶어하다"
            },
            {
                "word": "govern",
                "meaning": "통치하다; 운영하다"
            },
            {
                "word": "framework",
                "meaning": "틀; 체제, 구조"
            },
            {
                "word": "acute",
                "meaning": "심각한; 예리한; 격렬한"
            },
            {
                "word": "construct",
                "meaning": "건설하다, 구성하다;"
            },
            {
                "word": "expansion",
                "meaning": "확장, 확대, 팽창"
            },
            {
                "word": "implicate",
                "meaning": "연루시키다, 관련시키다"
            },
            {
                "word": "heir",
                "meaning": "상속인, 후계자"
            },]
    },
    {
        detailId: "302-09",
        list: [{
            "word": "concede",
            "meaning": "인정하다; 양보하다, 허용하다"
        },
            {
                "word": "explode",
                "meaning": "폭발하다, 터지다"
            },
            {
                "word": "entreat",
                "meaning": "간청하다, 탄원하다"
            },
            {
                "word": "moss",
                "meaning": "이끼"
            },
            {
                "word": "customer",
                "meaning": "고객, 손님"
            },
            {
                "word": "synonym",
                "meaning": "동의어, 유의어"
            },
            {
                "word": "qualify",
                "meaning": "자격을 얻다, 자격을 주다, 자격을 갖추다"
            },
            {
                "word": "measure",
                "meaning": "재다, 측정하다;"
            },
            {
                "word": "efficient",
                "meaning": "능률적인, 효율적인; 유능한"
            },
            {
                "word": "fascinate",
                "meaning": "매혹하다, 반하게 하다"
            },]
    },
    {
        detailId: "302-10",
        list: [{
            "word": "suffrage",
            "meaning": "투표권, 선거권"
        },
            {
                "word": "wander",
                "meaning": "거닐다, 돌아다니다"
            },
            {
                "word": "stare",
                "meaning": "빤히 쳐다보다, 응시하다"
            },
            {
                "word": "facilitate",
                "meaning": "용이하게 하다, 촉진하다"
            },
            {
                "word": "prosecution",
                "meaning": "기소, 고발; 검찰"
            },
            {
                "word": "grade",
                "meaning": "등급; 학년; 성적;"
            },
            {
                "word": "conclusion",
                "meaning": "결론, 판정, 타결"
            },
            {
                "word": "break",
                "meaning": "휴식, 휴가;"
            },
            {
                "word": "sigh",
                "meaning": "한숨 쉬다;"
            },
            {
                "word": "constitution",
                "meaning": "헌법; 구성; 체격"
            },]
    },
    {
        detailId: "302-11",
        list: [{
            "word": "myth",
            "meaning": "신화; 미신, (근거 없는) 이야기"
        },
            {
                "word": "prolong",
                "meaning": "연장하다, 늘이다"
            },
            {
                "word": "contemplate",
                "meaning": "심사숙고하다, 고려하다; 예상하다"
            },
            {
                "word": "imply",
                "meaning": "암시하다, 함축하다; 의미하다"
            },
            {
                "word": "compression",
                "meaning": "압축; 요약"
            },
            {
                "word": "fair",
                "meaning": "공평한; 타당한; 상당한;"
            },
            {
                "word": "count",
                "meaning": "(수를) 세다, 계산하다; 중요하다"
            },
            {
                "word": "resident",
                "meaning": "주민, 거주자;"
            },
            {
                "word": "exploit",
                "meaning": "이용하다, 착취하다;"
            },
            {
                "word": "descriptive",
                "meaning": "묘사하는, 서술하는, 설명적인; 기술적인"
            },]
    },
    {
        detailId: "302-12",
        list: [{
            "word": "strife",
            "meaning": "갈등, 불화, 다툼"
        },
            {
                "word": "seal",
                "meaning": "봉인하다, 밀봉하다;"
            },
            {
                "word": "Celsius",
                "meaning": "섭씨의"
            },
            {
                "word": "coordinate",
                "meaning": "조직화하다, 조정하다, 협력하다"
            },
            {
                "word": "defense",
                "meaning": "방어, 수비; (법률) 변호"
            },
            {
                "word": "genial",
                "meaning": "친절한; 따뜻한"
            },
            {
                "word": "contrary",
                "meaning": "반대로, 반대의, 정반대의"
            },
            {
                "word": "method",
                "meaning": "방법, 방식, 수단"
            },
            {
                "word": "increase",
                "meaning": "증가하다;"
            },
            {
                "word": "district",
                "meaning": "지역, 구역"
            },]
    },
    {
        detailId: "302-13",
        list: [{
            "word": "exertion",
            "meaning": "노력; (권력 등의) 행사; 분투"
        },
            {
                "word": "line",
                "meaning": "선, 줄;"
            },
            {
                "word": "mob",
                "meaning": "폭도, 군중, 무리"
            },
            {
                "word": "embed",
                "meaning": "박아 넣다, 끼워 넣다; (마음 속 등에) 깊이 새겨 두다"
            },
            {
                "word": "everything",
                "meaning": "모든 것"
            },
            {
                "word": "pain",
                "meaning": "아픔, 통증, 고통"
            },
            {
                "word": "brief",
                "meaning": "간결한, 짧은, 간단한;"
            },
            {
                "word": "intermission",
                "meaning": "(공연 등의) 휴식시간"
            },
            {
                "word": "active",
                "meaning": "활동적인, 적극적인, 활발한"
            },
            {
                "word": "usage",
                "meaning": "용법, 사용(법)"
            },]
    },
    {
        detailId: "302-14",
        list: [{
            "word": "infer",
            "meaning": "추측하다, 추론하다; 암시하다"
        },
            {
                "word": "below",
                "meaning": "아래에"
            },
            {
                "word": "safeguard",
                "meaning": "안전장치, 안전책;"
            },
            {
                "word": "help",
                "meaning": "돕다;"
            },
            {
                "word": "maze",
                "meaning": "미로"
            },
            {
                "word": "unique",
                "meaning": "유일무이한, 독특한, 특별한"
            },
            {
                "word": "explore",
                "meaning": "탐험하다, 탐사하다, 탐구하다"
            },
            {
                "word": "prescribe",
                "meaning": "처방하다; 규정하다; 지시하다"
            },
            {
                "word": "panic",
                "meaning": "공황, 당황; 공포;"
            },
            {
                "word": "tactic",
                "meaning": "(주로 복수로) 전략, 전술"
            },]
    },
    {
        detailId: "302-15",
        list: [{
            "word": "heredity",
            "meaning": "유전"
        },
            {
                "word": "debilitate",
                "meaning": "심신을 약화시키다, 쇠약하게 하다"
            },
            {
                "word": "ascertain",
                "meaning": "알아내다, 확인하다"
            },
            {
                "word": "sublime",
                "meaning": "절묘한, 숭고한, 장엄한"
            },
            {
                "word": "soul",
                "meaning": "영혼"
            },
            {
                "word": "wayward",
                "meaning": "다루기 힘든, 말을 안 듣는"
            },
            {
                "word": "approve",
                "meaning": "승인하다, 찬성하다, 허가하다; 인정하다"
            },
            {
                "word": "pervade",
                "meaning": "널리 펴지다;"
            },
            {
                "word": "comet",
                "meaning": "혜성"
            },
            {
                "word": "fallacy",
                "meaning": "그릇된 생각, 오류, 착오"
            },]
    },
    {
        detailId: "302-16",
        list: [
            {
                "word": "shuffle",
                "meaning": "발을 끌며 걷다; (게임을 하기 위해 카드를) 섞다; 이리저리 움직이다;"
            },
            {
                "word": "sue",
                "meaning": "고소하다, 소송을 제기하다"
            },
            {
                "word": "barbarous",
                "meaning": "악랄한, 잔혹한"
            },
            {
                "word": "dwindling",
                "meaning": "줄어드는, 감소하는"
            },
            {
                "word": "contempt",
                "meaning": "경멸, 모욕, 멸시"
            },
            {
                "word": "gigantic",
                "meaning": "거대한"
            }
        ]
    }
]

