import styled from "styled-components";

export const Wrap = styled.div`
    // position: fixed;
    // width: 100%;
    // height: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // left: 0;
    // top: 0;
`

export const Inner = styled.div`
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // gap: 10px;

    // > div {
    //     font-size: 14px;
    //     font-weight: 700;
    // }
    
    // a{
    //     margin-top: 50px;
    //     font-size: 16px;
    //     font-weight: 700;
    //     border-bottom: 1px solid #000000;
    //     text-align: center;
    //     span{
    //         font-size: 12px;
    //     }
    // }
`

export const CopyRightBx = styled.div`
    // font-size: 14px;
    // font-weight: 400;
    // line-height: 16px;
    // margin-top: 50px;
    // text-align: center;
    // font-family: sans-serif !important;
    // box-sizing: border-box;
    // padding: 0 10px;
`