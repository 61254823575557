import * as S from "./styles";
import {motion} from "framer-motion";
import {useState} from "react";

interface Props {
    answerList?: any[];
    onClickAnswer: (word: any) => void;
}

const AnswerList = ({answerList, onClickAnswer}: Props) => {
    const [clickAnswer, setClickAnswer] = useState<number>(-1);
    const [isClick, setClick] = useState<boolean>(false);
    const onClick = (word: any, idx: number) => {
        if (isClick) return;
        setClick(true);

        onClickAnswer(word);
        setClickAnswer(idx);
    }

    return (
        <S.AnswerListBx>
            <motion.ul
                initial={{opacity: 0, y: 32}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0, y: 32}}
                transition={{duration: 0.5, ease: 'easeOut'}}
            >
                {
                    answerList && answerList.length > 0 && answerList.map((item, idx) => (
                        <S.AnswerListItem key={`answer-list-${idx}`}>
                            <button onClick={() => onClick(item, idx)}>
                                <S.Num $isClick={clickAnswer === idx} $isAnswer={item.isAnswer}>
                                    {idx + 1}
                                    {
                                        isClick && item.isAnswer && <>
                                            <S.IconAnswer
                                                initial={{opacity: 0, scale: 0.5 }}
                                                animate={{opacity: 1, scale: 1 }}
                                                transition={{duration: 0.25, ease: 'easeOut'}}
                                            />
                                        </>
                                    }
                                </S.Num>
                                <S.Word $isClick={clickAnswer === idx} $isAnswer={item.isAnswer}>
                                    {item.word}
                                </S.Word>
                            </button>
                        </S.AnswerListItem>
                    ))
                }
            </motion.ul>
        </S.AnswerListBx>
    )
}

export default AnswerList;