import * as S from "./styles";
import {getFilter} from "../../../../libs/check";
import {motion} from "framer-motion";


interface Props {
    rKey: string;
    sentence: any[];
    word: string;
    translation: string;
}

const QuizSentence = ({rKey, sentence, word, translation}: Props) => {
    return (
        <S.Wrap>
            <dl>
                <dt>
                    <ul>
                        {
                            sentence && sentence.length > 0 && sentence.map((item, idx) => (
                                <li
                                    key={`sentence-${idx}-${rKey}`}
                                    style={{
                                        'color': getFilter(item.quizWord, word) ? '#4545ee' : 'black',
                                    }}
                                >
                                    <motion.span
                                        initial={{y: 32}}
                                        animate={{y: 0}}
                                        exit={{y: 32}}
                                        transition={{duration: 0.5, ease: 'easeOut'}}
                                    >
                                        <motion.i
                                            style={{'display': 'inline-block'}}
                                            initial={{y: 32}}
                                            animate={{y: item.isAnswer ? 32 : 0}}
                                            transition={{duration: 0.25, ease: 'easeOut'}}
                                        >
                                            {item.quizWord}
                                        </motion.i>
                                    </motion.span>
                                </li>
                            ))
                        }
                    </ul>
                </dt>
                <dd>
                    <motion.span
                        initial={{opacity: 0, y: 32}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: 32}}
                        transition={{duration: 0.5, ease: 'easeOut'}}
                    >
                        {translation}
                    </motion.span>
                </dd>
            </dl>
        </S.Wrap>
    )
}

export default QuizSentence;