import styled from "styled-components";
import {motion} from "framer-motion";

export const Wrap = styled(motion.div)`
    position: fixed;
    width: 100dvw;
    box-sizing: border-box;
    height: 100dvh;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Inner = styled.div`
    position: absolute;
    max-width: 600px;
    //max-height: 800px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
    left: 50%;
    transform: translateX(-50%);
`

export const Content = styled(motion.div)`
    
`

export const Bg = styled(motion.div)`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    left: 0;
    top: 0;
    z-index: 2000;
`