import styled from "styled-components";

export const Wrap = styled.div`
    padding-top: 20px;
`

export const Inner = styled.div`
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 20px 100px;
    box-sizing: border-box;
`

export const TitleBx = styled.div`
    margin-bottom: 20px;
    dl{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        dt{
            font-size: 24px;
            font-weight: 700;
        }
        dd{
            
        }
    }
`

export const MyRanking = styled.div`
    margin-bottom: 50px;
    padding: 5px 0;
    
    h3{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        text-align: center;
    }

    dl {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        dt {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            font-size: 18px;
            font-weight: 700;

            span {
                font-size: 14px;
                font-weight: 700;
                color: gray;
            }
        }

        dd {
            font-size: 16px;
            font-weight: 600;
        }
    }
`

export const RankingListBx = styled.div`
    width: 100%;
    h3{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        text-align: center;
    }
    
    ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        li {
            padding: 5px 0;
            border-bottom: 1px solid #eeeeee;

            dl {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                dt {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 20px;
                    font-size: 14px;
                    font-weight: 700;

                    span {
                        font-size: 14px;
                        font-weight: 700;
                        color: gray;
                    }
                }

                dd {
                    font-size: 16px;
                    font-weight: 600;
                    span{
                        display: inline-block;
                        width: 45px;
                    }
                }
            }
        }
    }
`